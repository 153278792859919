import * as React from 'react';
import { Navigate } from 'react-router-dom';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import "../Amenities.css"

import BackgroundImage from '../components/Cityline';

import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';

import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

import axios from 'axios';
import AppConfig from '../services/app-config';
import AuthService from '../services/auth';
import { CardHeader } from '@mui/material';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import PersonIcon from '@mui/icons-material/Person';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

import EventBusyIcon from '@mui/icons-material/EventBusy';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import appConfig from '../services/app-config';

export default function Home({setNavbarState, navbarState}) {
  const [redirect, setRedirect] = React.useState({ trigger: false, route: '/' });
  const [loading, setLoading] = React.useState(true);
  const [activeReservations, setActiveReservations] = React.useState([]);
  const [pastReservations, setPastReservations] = React.useState([]);
  const [showBackdrop, setShowBackdrop] = React.useState(false);
  const [tabValue, setTabValue] = React.useState('active');
  const [snackbar, setSnackbar] = React.useState({ open: false, message: '', severity: 'success' });
  const [searchTerm, setSearchTerm] = React.useState('');
  const apiUrl = process.env.REACT_APP_MGO_API_URL;
  
  let tab = new URLSearchParams(location.search).get('tab');

  const userCors = process.env.REACT_APP_USE_CORS;
  if ( userCors === "true") {
    axios.defaults.withCredentials = true
  }

  const handleTabChange = (event,newValue) => {
    setTabValue(newValue);
    fetchInfo(newValue);
  }

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  function convertEpochsToTimeString(epoch1, epoch2) {
    const date1 = new Date(epoch1 * 1000);
    const date2 = new Date(epoch2 * 1000);

    const day = date1.getDate();
    const month = date1.toLocaleString('default', { month: 'short' });
    const time1 = date1.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    const time2 = date2.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });

    return `${day}/${month} ${time1} - ${time2}`;
  }

  const fetchInfo = (CurrentTabValue) => {
    setShowBackdrop(true);
    setLoading(true);

    axios.get(`${apiUrl}/reservations/${CurrentTabValue}/list`)
      .then(response => {
        AppConfig.parseResponse(response);
        if (response.status === 200) {
          if (CurrentTabValue === 'active') {
          const dataArray = Object.entries(response.data.data).map(([id, value]) => {
            const amenidadId = id.split('-')[0];
            return { id, ...value, amenity: amenidadId };
          });
          dataArray.sort((a, b) => a.inicio - b.inicio);
          setActiveReservations(dataArray);
          }
          else if (CurrentTabValue === 'past') {
            const dataArray = Object.entries(response.data.data).map(([id, value]) => {
              const amenidadId = id.split('-')[0];
              return { id, ...value, amenity: amenidadId };
            });
            dataArray.sort((a, b) => a.inicio - b.inicio);
            setPastReservations(dataArray.reverse());
          }
          setShowBackdrop(false);
          setLoading(false);
        }
      })
      .catch(error => {
        console.error('Error fetching issues:', error);
        if (error.response && error.response.data && error.response.data.code === 401) {
          setRedirect({ trigger: true, route: '/login' })
        }
      });
  }

  React.useEffect(() => {
    setNavbarState({...navbarState, showBack: true, showOptions: false, showSearch: false})
    if (!AuthService.userRoleIncludes(["admin","receptor"])) {
      setRedirect({ trigger: true, route: '/amenidades' });
    }
    if (tab !== null) { 
      setRedirect({ trigger: true, route: '/amenidades' });
      setTabValue(tab);
    }
    fetchInfo(tabValue);
  }, [tabValue]);

  const filterReservations = (reservations) => {
    return reservations.filter(reservation => 
      (reservation.nombre_amenidad?.toLowerCase().includes(searchTerm.toLowerCase()) ?? false) ||
      (reservation.unidad?.toLowerCase().includes(searchTerm.toLowerCase()) ?? false)
    );
  };

  const renderReservations = (reservations) => {
    const filteredReservations = filterReservations(reservations);
    
    if (filteredReservations.length === 0) {
      return (
        <Card variant="outlined" style={{ marginBottom: '0.5rem', borderRadius: '10px', textAlign: 'center' }}>
          <CardContent>
            <Typography variant="h5" component="div" sx={{ mt: 4 ,mb: 4}}>
              <b>No hay reservaciones que coincidan con la búsqueda.</b>
            </Typography>
          </CardContent>
        </Card>
      );
    }

    return filteredReservations.map((reservation) => (
      <Card
        key={reservation.id}
        variant="outlined"
        sx={{ mb: 1.2 }}
        onClick={() => setRedirect({ trigger: true, route: `/reservacion?id=${reservation.id}` })}
      >
        <CardHeader
          className="card-header"
          style={{
            backgroundImage: `url(${apiUrl}/img/amenities/${reservation.amenity}.jpg)`,
          }}
        />
        <CardContent sx={{ pb: 0 }}>
          <Typography variant="h6" sx={{ textAlign: 'right', mt: -1.5, mb: 1}} color="gray">
            Reservacion: {reservation.unidad?.toUpperCase() ?? 'N/A'} - {reservation.nombre_amenidad ?? 'N/A'}
          </Typography>
          <Typography variant="body1" component="div" color="text.secondary" >
            <b>{convertEpochsToTimeString(reservation.inicio,reservation.fin)}</b>
          </Typography>
          {tabValue === 'active' ? (
            reservation.confirmada ? (
              <Typography variant="body1" color="text.secondary">
                <CheckCircleOutlineIcon style={{ fontSize: '1rem', verticalAlign: 'middle' }} /> Confirmada
              </Typography>
            ) : (
              <Typography variant="body1" color="text.secondary">
                <EventBusyIcon style={{ fontSize: '1rem', verticalAlign: 'middle' }} /> Pendiente de Confirmacion
              </Typography>
            )
          ) : (
            <Typography variant="body1" color="text.secondary">
              <CheckCircleOutlineIcon style={{ fontSize: '1rem', verticalAlign: 'middle' }} /> Pasada
            </Typography>
          )}
        </CardContent>
        <CardActions sx={{ justifyContent: 'flex-end', pr: 1.5, pb: 1.5 }}>
          <Button color="secondary" variant="contained" size="medium" onClick={() => setRedirect({ trigger: true, route: `/reservacion?id=${reservation.id}` })}>
            Mas Detalles
          </Button>
        </CardActions>
      </Card>
    ));
  };

  return (
    <Container maxWidth="sm">
      {redirect.trigger && (
        <Navigate to={redirect.route} replace={true} />
      )}
      <Box sx={{ mt: 1 }}>
        <Snackbar open={snackbar.open} autoHideDuration={8000} anchorOrigin={{ vertical: "top", horizontal: "center" }} sx={{mt: "3.2rem"}} onClose={handleSnackbarClose}>
          <Alert onClose={handleSnackbarClose} severity={snackbar.severity}>
            {snackbar.message}
          </Alert>
        </Snackbar>
        <Backdrop open={showBackdrop} sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, color: '#fff' }}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <Tabs value={tabValue} onChange={handleTabChange} aria-label="user tabs" variant="fullWidth" sx={{ mt: 0, mb: 0.2 }} >
          <Tab label="Vigentes" value={'active'}/>
          <Tab label="Pasadas" value={'past'}/>
        </Tabs>
        <TextField
          fullWidth
          variant="outlined"
          placeholder="Buscar por nombre de amenidad o unidad"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          sx={{ mb: 2, mt: 1 }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
        {!loading && (
          tabValue === "past" ? renderReservations(pastReservations) : renderReservations(activeReservations)
        )}
      </Box>
      <BackgroundImage />
    </Container>
  );
}