import React, { useState } from 'react';
import { TextField, Button, Box, Input } from '@mui/material';
import { Card, CardContent } from '@mui/material';
import Typography from '@mui/material/Typography';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import ClearIcon from '@mui/icons-material/Clear';
import BackgroundImage from '../components/Cityline';

import Resizer from 'react-image-file-resizer';

import AuthService from '../services/auth';
import AppConfig from '../services/app-config';

import { Navigate } from 'react-router-dom';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import axios from 'axios';

function NewReport({ setNavbarState, navbarState}) {
    const [redirect, setRedirect] = React.useState({ trigger: false, route: '/' });
    const [snackbar, setSnackbar] = React.useState({ open: false, message: '', severity: 'success' });
    const [area, setArea] = useState('');
    const [descripcionBreve, setDescripcionBreve] = useState('');
    const [descripcionDetallada, setDescripcionDetallada] = useState('');
    const [image, setImage] = useState(null);
    const [showBackdrop, setShowBackdrop] = React.useState(false);

    const apiUrl = process.env.REACT_APP_MGO_API_URL;

    const userCors = process.env.REACT_APP_USE_CORS;
    if ( userCors === "true") {
        axios.defaults.withCredentials = true
    }

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setSnackbar({ ...snackbar, open: false });
      };
    
    const handleAreaChange = (e) => {
        setArea(e.target.value);
    };

    const handleDescripcionBreveChange = (e) => {
        setDescripcionBreve(e.target.value);
    };

    const handleDescripcionDetalladaChange = (e) => {
        setDescripcionDetallada(e.target.value);
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file.size > 1024 * 1024) {
            Resizer.imageFileResizer(
            file,
            800, // Max width
            800, // Max height
            'JPEG', // Output format
            80, // Quality
            0, // Rotation
            (compressedFile) => {
                setImage(compressedFile);
                setShowBackdrop(false);
            },
            'blob' // Output type
            );
        } else {
            if (file.type.startsWith('image/')) {
            setImage(file);
            setShowBackdrop(false);
            } else {
            setShowBackdrop(false);
            setSnackbar({ open: true, message: 'Por favor, solo utilice imagenes', severity: 'error' });
            setImage(null);
            }
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (area.trim() === '' || descripcionBreve.trim() === '' || descripcionDetallada.trim() === '') {
            setSnackbar({ open: true, message: 'Todos los campos son requeridos', severity: 'error' });
            return;
        }

        setShowBackdrop(true);
        const formData = new FormData();
        formData.append('area', area);
        formData.append('descripcionBreve', descripcionBreve);
        formData.append('descripcionDetallada', descripcionDetallada);
        if (image) {
            formData.append('image', image);
        }
        axios.post(`${apiUrl}/issue/create`, formData)
            .then((response) => {
                AppConfig.parseResponse(response);
                if (response.status === 200) {
                    setRedirect({ trigger: true, route: '/reportes' });
                  } else if (response.status === 422) {
                    setSnackbar({ open: true, message: response.data.data.mensaje, severity: 'error' });
                  }
                setShowBackdrop(false);
            })
            .catch((error) => {
                if(error.response && error.response.data && error.response.data.code === 422) {
                setSnackbar({ open: true, message: error.response.data.data.mensaje, severity: 'error' });
                }
                else  {
                console.error('Error commenting:', error);
                setSnackbar({ open: true, message: "Hubo un error al intentar agregar tu reporte, porfavor intente mas tarde.", severity: 'error' })};
                setShowBackdrop(false);
            });
    };

    React.useEffect(() => {
        setNavbarState({...navbarState, showBack: true, showOptions: false, showSearch: false});
    },[setNavbarState, navbarState]);

    return (
        <Box>
            {redirect.trigger && (
                <Navigate to={redirect.route} replace={true} />
            )}
            <Snackbar open={snackbar.open} autoHideDuration={8000} anchorOrigin={{ vertical: "top", horizontal: "center" }} sx={{mt: "3.2rem"}} onClose={handleSnackbarClose}>
                <Alert onClose={handleSnackbarClose} severity={snackbar.severity}>
                {snackbar.message}
                </Alert>
            </Snackbar>
            <Backdrop open={showBackdrop} sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, color: '#fff' }}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <Box
                sx={{ mt: 2 ,py: 0.5, px: 0.5, display: 'block', marginLeft: 'auto', marginRight: 'auto', maxWidth: "550px" }}
            >
                <CardContent>
                    <Typography variant="h5" color="textSecondary">
                        {descripcionBreve}
                    </Typography>
                    <TextField sx={{ mb: 2, mt: 2}} fullWidth size="medium" id='area' label="Area" variant="outlined" placeholder='Ej. 1er Nivel Torre 3' onChange={handleAreaChange}/>
                    <TextField sx={{ mb: 2}} fullWidth size="medium"id='shortdescript' label="Cual es el problema" variant="outlined" placeholder='Ej. Fuga de Agua' onChange={handleDescripcionBreveChange}/>
                    <TextField sx={{ mb: 2}} rows={4} multiline fullWidth size="medium" id='lomgdescript' label="Descripcion detallada" variant="outlined" placeholder='Incluye todos los detalles para facilitar identificar el problema, Ej. Hay una fuga de agua al lado de la puerta de ingreso' onChange={handleDescripcionDetalladaChange}/>
                    
                    {image && (
                        <Box style={{ position: 'relative' }}>
                            <ClearIcon style={{ position: 'absolute', top: '-4px', left: '-8px', cursor: 'pointer' }} onClick={() => setImage(null)} />
                            <img src={URL.createObjectURL(image)} alt="Selected Image" 
                                style={{
                                    maxWidth: '60%',
                                    height: 'auto',
                                    objectFit: 'cover',
                                    borderRadius: '10px',
                                    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                                    marginTop: '8px'
                                }}
                            />
                        </Box>
                    )}
                    <label htmlFor="contained-button-file">
                        <Button variant="text" color='secondary' component="span" startIcon={<AddPhotoAlternateIcon />}>
                            {image ? "Cambiar"  : "Agregar"} Imagen
                        </Button>
                        <Input
                            type="file"
                            onChange={handleImageChange}
                            id="contained-button-file"
                            sx={{ display: 'none' }}
                        />
                    </label>
                </CardContent>
                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                    <Typography variant="caption" color="textSecondary">
                        Recuerda que todos los vecinos de {AuthService.getCurrentUserOrg()} pueden ver los reportes
                    </Typography>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 2,  py: 0.5, px: 0.5 }}>
                    <Button variant="contained" fullWidth color="secondary" size="large" onClick={handleSubmit}>Enviar Reporte</Button>
                    <Button variant="text" color="primary" size="large" sx={{mt : 1.5}} onClick={() => setRedirect({ trigger: true, route: '/reportes' })}>Cancelar</Button>
                </Box>
                
            </Box>
            <BackgroundImage />
        </Box>
    );
}

export default NewReport;
