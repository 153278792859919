import * as React from 'react';
import { Navigate } from 'react-router-dom';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import AuthService from '../services/auth.js';
import "../Amenities.css"

import BackgroundImage from '../components/Cityline';

import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import SendIcon from '@mui/icons-material/Send';
import Divider from '@mui/material/Divider';

import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';

import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';


import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';

import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import PersonIcon from '@mui/icons-material/Person';
import CommentIcon from '@mui/icons-material/Comment';
import LocationOnIcon from '@mui/icons-material/LocationOn';

import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import TextField from '@mui/material/TextField';

// import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import dayjs from 'dayjs';
import Drawer from '@mui/material/Drawer';

import axios from 'axios';
import AppConfig from '../services/app-config';

export default function Issue({setNavbarState, navbarState, window}) {
  const [redirect, setRedirect] = React.useState({ trigger: false, route: '/' });
  const [loading, setLoading] = React.useState(true);
  const [drawerShow, setDrawerShow] = React.useState(false);
  const [showBackdrop, setShowBackdrop] = React.useState(false);
  const [reservationDialog,setReservationDialog] = React.useState(false);
  const [snackbar, setSnackbar] = React.useState({ open: false, message: '', severity: 'success' });
  const [refresh,setRefresh] = React.useState(false);
  const [issue,setIssue] = React.useState({});
  const [issueComment,setIssueComment] = React.useState("");

  const issueId = new URLSearchParams(location.search).get('id');
  const apiUrl = process.env.REACT_APP_MGO_API_URL;

  const stateFriendlyNames = AppConfig.getLocal("issues")

  const userCors = process.env.REACT_APP_USE_CORS;
  if ( userCors === "true") {
    axios.defaults.withCredentials = true
  }

  const closeReservationDialog = () => {
    setReservationDialog(false);
  }

  const triggerRefresh = () => {
    setRefresh(!refresh);
  }

  const updateIssue = (estado) => {
    setLoading(true);
    setShowBackdrop(true);
    axios.post(`${apiUrl}/issue/update/${issueId}`, {estado: estado})
      .then(response => {
        AppConfig.parseResponse(response);
        if (response.status === 200) {
          setSnackbar({ open: true, message: "Estado actualizado correctamente", severity: 'success' });
          fetchInfo(issueId);
        } else if (response.status === 422) {
          setSnackbar({ open: true, message: response.data.data.mensaje, severity: 'error' });
        }
        setLoading(false);
        setShowBackdrop(false);
      })
      .catch(error => {
        if(error.response && error.response.data && error.response.data.code === 422) {
          setSnackbar({ open: true, message: error.response.data.data.mensaje, severity: 'error' });
        }
        else  {
        console.error('Error fetching issues:', error);
        setSnackbar({ open: true, message: "Hubo un error al intentar actualizar el estado del ticket, porfavor intente mas tarde.", severity: 'error' })};
        setLoading(false);
        setShowBackdrop(false);
      });
  }

  const commentIssue = () => {
    setShowBackdrop(true);
    if (issueComment === "") {
      setSnackbar({ open: true, message: "No puedes enviar un comentario vacio", severity: 'error' });
      setShowBackdrop(false);
      return;
    }
    
    axios.post(`${apiUrl}/issue/comment/${issueId}`, {comentario: issueComment})
    .then(response => {
      setIssueComment("");
      AppConfig.parseResponse(response);
      if (response.status === 200) {
        setSnackbar({ open: true, message: "Comentario agregado", severity: 'success' });
        fetchInfo(issueId);
      } else if (response.status === 422) {
        setSnackbar({ open: true, message: response.data.data.mensaje, severity: 'error' });
      }
      setShowBackdrop(false);
    })
    .catch(error => {
      if(error.response && error.response.data && error.response.data.code === 422) {
        setSnackbar({ open: true, message: error.response.data.data.mensaje, severity: 'error' });
      }
      else  {
      console.error('Error commenting:', error);
      setSnackbar({ open: true, message: "Hubo un error al intentar agregar tu comentario, porfavor intente mas tarde.", severity: 'error' })};
      setShowBackdrop(false);
    });
  }

  const handleDrawerToggle = () => {
    setDrawerShow(!drawerShow);
  }

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  const ceil = (option, dayjsClass) => {
    dayjsClass.prototype.ceil = function (unit, amount) {
        return this.add(amount - (this.get(unit) % amount), unit).startOf(unit);
    };
  };
  dayjs.extend(ceil);


  const fetchInfo = (id) => {

    axios.get(`${apiUrl}/issue/get/${id}`)
      .then(response => {
        AppConfig.parseResponse(response);
        if (response.status === 200) {
          const issueData = response.data.data;
          const lastEstado = issueData.estados[issueData.estados.length - 1];
          issueData.estado = lastEstado;
          setIssue(issueData);
          setLoading(false);
          setShowBackdrop(false);
        }
      })
      .catch(error => {
        setRedirect({ trigger: true, route: '/reportes' });
        console.error('Error fetching issues:', error);
        setSnackbar({ open: true, message: 'Hubo un error obteniendo los detalles del reporte', severity: 'error' });
        setShowBackdrop(false);
      });
  }

  const fabStyle = {
    position: 'fixed',
    bottom: 32,
    right: 32
  };

  const reservationDrawer = (
    <Card
    sx={{ py: 3, px: 2, display: 'block', marginLeft: 'auto', marginRight: 'auto', borderTopLeftRadius: "20px", borderTopRightRadius: "20px", borderBottomRightRadius: "0px", borderBottomLeftRadius: "0px", maxWidth: "550px", width: "100%" }}>
        
      <CardContent>
      </CardContent>
      <CardActions>
        <Button size="medium" color="inherit" variant="outlined" onClick={handleDrawerToggle}>Cancelar</Button>
        <Button size="medium" color="secondary" variant="contained" >Reservar</Button>
      </CardActions>
    </Card>
  );

  React.useEffect(() => {
    setNavbarState({...navbarState, showBack: true, showOptions: false, showSearch: false})
    if (issueId === null) {
      setRedirect({ trigger: true, route: '/reportes' });
    }
    setShowBackdrop(true);
    setLoading(true);
    fetchInfo(issueId);
  }, [refresh]);

const container = window !== undefined ? () => window().document.body : undefined;
return (
    <>
    <Container maxWidth="sm">
      {redirect.trigger && (
        <Navigate to={redirect.route} replace={true} />
      )}
    <Snackbar open={snackbar.open} autoHideDuration={3000} anchorOrigin={{ vertical: "top", horizontal: "center" }} sx={{mt: "3.2rem"}} onClose={handleSnackbarClose}>
      <Alert onClose={handleSnackbarClose} severity={snackbar.severity}>
        {snackbar.message}
      </Alert>
    </Snackbar>
    <Box sx={{ mt: 1 }}>
      <Backdrop open={showBackdrop} sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, color: '#fff' }}>
        <CircularProgress color="inherit" />
      </Backdrop>
        {!loading && (
          <>
         <img src={`${apiUrl}/img/issue/${issueId}.jpg`} alt="Issue Image" style={{ width: '100%', borderTopLeftRadius: '15px', borderTopRightRadius: '15px', paddingLeft: 0}} />
          <Card
            key={issueId}
            variant="outlined"
            sx={{ mt: -0.6, borderTopLeftRadius: "0px", borderTopRightRadius: "0px" }}
          >
            <CardContent>
              <Typography variant="body1" color="text.secondary" sx={{ textAlign: 'right', mt: -1.5 }}>
                Ticket {issueId.toUpperCase()}
              </Typography>
              <Typography variant="h5" component="div" sx={{mb: 1}}>
                <b>{issue.titulo}</b>
              </Typography>
              <Typography variant="body1" color="text.primary" sx={{mb: 1.5}}>
                {issue.descripcion} 
              </Typography>
              
              <Typography variant="body1" color="text.secondary" sx={{mb: 0.2}}>
                <LocationOnIcon style={{ fontSize: '1rem', verticalAlign: 'middle' }} /> <b>Area:</b> {AppConfig.titleCase(issue.area)}
              </Typography>
              <Typography variant="body1" color="text.secondary" sx={{mb: 0.2}}>
                <PersonIcon style={{ fontSize: '1rem', verticalAlign: 'middle' }} /> <b>Reportado por:</b> {AppConfig.titleCase(issue.creador.usuario_nombre)}
              </Typography>
              <Typography variant="body1" color="text.secondary" sx={{mb: 0.2}}>
                <ArrowCircleRightIcon style={{ fontSize: '1rem', verticalAlign: 'middle' }} /> <b>Estado:</b> {stateFriendlyNames[issue.estado.estado].nombre_largo}
              </Typography>
              <Divider sx={{ mt: 1, mb: 2 }} />
              <Typography variant="h6" component="div" sx={{mt: 1}}>
                Actualizaciones
              </Typography>
              <Timeline position="right">
                {issue.estados.map((estado, index) => (
                  <TimelineItem key={`estado-${index}`}>
                    <TimelineOppositeContent>
                      <Typography variant="body2" color="text.secondary">
                        {AppConfig.formatFriendlyDate(estado.fecha)}
                      </Typography>
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                      <TimelineDot color="primary" />
                      {index !== issue.estados.length - 1 && <TimelineConnector />}
                    </TimelineSeparator>
                    <TimelineContent>
                      <Typography variant="body1" color="text.primary">
                        {stateFriendlyNames[estado.estado].nombre_corto}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        <PersonIcon style={{ fontSize: '1rem', verticalAlign: 'middle' }} /> {estado.usuario_nombre}
                      </Typography>
                    </TimelineContent>
                  </TimelineItem>
                ))}
              </Timeline>
              { (AuthService.userRoleIncludes(["admin"])) && stateFriendlyNames[issue.estado.estado].siguiente_estado.map((estado, index) => (
                <Button
                  key={`estado-${index}`}
                  variant={estado === 'cancelado' ? "text" : "contained"}
                  color={estado === 'cancelado' ? "inherit" : "secondary"}
                  fullWidth
                  sx={{ mb: 0.1 }}
                  onClick={() => updateIssue(estado)}
                >
                  {estado === 'cancelado' ? 'Cancelar Ticket' : `Actualizar a ${stateFriendlyNames[estado].nombre_corto}`}
                </Button>
              ))}
              <Divider sx={{ mt: 2, mb: 2 }} />
              <Typography variant="h6" component="div" sx={{mt: 1}}>
                Comentarios
              </Typography>
                  {issue.comentarios.length === 0 && (
                    <Typography variant="body1" color="text.secondary" sx={{ mb: 1.5, mt: 1.5, textAlign: 'center' }}>
                      No hay ningún comentario
                    </Typography>
                  )}
                  {issue.comentarios.map((comentario, index) => (
                    <Paper key={`comentario-${index}`} sx={{ mt: 1 }}>
                      <Card variant="outlined">
                        <CommentIcon sx={{ml: 1, mt: 1}}/>
                        <CardContent>
                          <Typography variant="body1" color="text.primary" sx={{mt: -1}}>
                           {comentario.comentario}
                          </Typography>
                          <Typography variant="body2" color="text.secondary" sx={{ textAlign: 'right' }}>
                            {comentario.usuario_nombre} - {AppConfig.formatFriendlyDate(comentario.fecha)}
                          </Typography>
                        </CardContent>
                      </Card>
                    </Paper>
                  ))}

                {issue.estado.estado !== 'cancelado' && issue.estado.estado !== 'resuelto' && (
                  <Box sx={{ mt: 1 }}>
                    <TextField
                      id="comentario-input"
                      label="Escribe tu Comentario"
                      variant="outlined"
                      fullWidth
                      multiline
                      rows={2}
                      onChange={(event) => setIssueComment(event.target.value)}
                      value={issueComment}
                    />
                    <Box sx={{ mt: 1, display: 'flex', justifyContent: 'flex-end' }}>
                      <IconButton color="secondary" sx={{ mt: -7 }} onClick={commentIssue}>
                        <SendIcon />
                      </IconButton>
                    </Box>
                  </Box>
                )}

            </CardContent>
            <CardActions >
            </CardActions>
          </Card>
          </>)}
    </Box>

    {!loading && (
    <>
    <Drawer
          container={container}
          anchor="bottom"
          variant="temporary"
          open={drawerShow}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          PaperProps={{ elevation: 0, style: { backgroundColor: "transparent" } }}
          sx={{
            display: { xs: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: '440' },
          }}
        >
          {reservationDrawer}
    </Drawer>
    <Dialog open={reservationDialog} onClose={closeReservationDialog}>
        <DialogTitle>Confirmar Reserva</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {reservationDialog ? <Typography>¿Está seguro que desea reservar esta amenidad el dia {amenity.dia_de_semana}, {date.format('DD')} de {amenity.mes} de {timeRanges.from.value.format('HH:mm')} a {timeRanges.to.value.format('HH:mm')} ?</Typography> : ''}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeReservationDialog} color="primary">
            Cancelar
          </Button>
          <Button color="secondary">
            Confirmar
          </Button>
        </DialogActions>
    </Dialog>
    </>
    )}
      
    <BackgroundImage />
    </Container>
  </>);
}