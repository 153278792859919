import * as React from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import Box from '@mui/material/Box';
import BottomNavigation from '@mui/material/BottomNavigation';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { Navigate } from 'react-router-dom';
import AppConfig from '../services/app-config';

const apiUrl = process.env.REACT_APP_MGO_API_URL;

export default function ShareCode({setNavbarState, navbarState}) {
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const code = params.get('code');
    const [value, setValue] = React.useState('recents');
    const [redirect, setRedirect] = React.useState({ trigger: false, route: '/' });
    const [imageSrc, setImageSrc] = React.useState(null);
    const [loading, setLoading] = React.useState(true);

    const userCors = process.env.REACT_APP_USE_CORS;
  if ( userCors === "true") {
    axios.defaults.withCredentials = true
  }

    const deactivateCode = async () => {
        try {
            const response = await axios.post(`${apiUrl}/visit/invalidate`, { access_code: code });
            AppConfig.parseResponse(response);
            if (response.status === 200) {
                setRedirect({ trigger: true, route: '/visitas' });
            }
        } catch (error) {
            setRedirect({ trigger: true, route: '/visitas' });
            console.log(error);
        }
    };

    const shareImage = async (share = true) => {
        const config = {
            responseType: 'blob', // important
        };
        const blob = await axios.get(`${apiUrl}/visit/create/image/${code}`, config)
            .then(res => new Blob([res.data], { type: res.headers['content-type'] }))
            .catch(error => {
                console.log(error)
                if (error.response.data.code === 401) {
                    setRedirect({ trigger: true, route: '/login' })
                } else {
                    setRedirect({ trigger: true, route: '/visits' })
                }
            })
        const reader = new FileReader();
        reader.onloadend = function () {
            setImageSrc(reader.result);
            setLoading(false);
        }
        reader.readAsDataURL(blob);
        const data = {
            files: [
                new File([blob], `Acceso_${code}.jpg`, {
                    type: blob.type,
                }),
            ]
        };

        if (share) {
            if (!navigator.share) return;
            try {
                await navigator.share(data);
            } catch (err) {
                console.error(err);
            }
        }
    };

    React.useEffect(() => {
        shareImage(false)
        setNavbarState({ ...navbarState, showBack: true, showOptions: false, showSearch: false})
    }, [])


    return (
        <Box sx={{ mt: 1 }}>
            {redirect.trigger && (
                <Navigate to={redirect.route} replace={true} />
            )}
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            {!loading &&
                (<>
                    <Box component="div" sx={{ overflow: 'auto', height: '80vh'}}>
                        <img src={imageSrc} alt="API Image" style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto', maxWidth: "99%", height: '70vh' }} />
                    </Box>
                    <Paper sx={{ display: 'block', marginLeft: 'auto', marginRight: 'auto', position: 'fixed', bottom: 0, left: 0, right: 0, height: "15%", borderTopLeftRadius: "20px", borderTopRightRadius: "20px", maxWidth: "450px" }} elevation={3}>
                        <BottomNavigation
                            value={value}
                            onChange={(event, newValue) => {
                                setValue(newValue);
                            }}
                            sx={{ mt: 4 }}
                        >
                            <Box id="fixedBox" sx={{ position: 'fixed', display: 'flex', flexDirection: 'row', gap: 1, alignItems: 'center', position: "fixed" }}>
                                <Button variant="text" onClick={deactivateCode}>Anular Acceso</Button>
                                <Button variant="contained" color="secondary" onClick={shareImage}>Compartir</Button>
                            </Box>
                        </BottomNavigation>
                    </Paper>
                </>)}
        </Box>
    );
}