import * as React from 'react';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Backdrop from '@mui/material/Backdrop';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';

import axios from 'axios';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { Navigate, useSearchParams } from 'react-router-dom';

import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import TextField from '@mui/material/TextField';

import AppConfig from '../services/app-config';
import AuthService from '../services/auth.js';
import BackgroundImage from '../components/Cityline';

import { getMessaging, getToken } from "firebase/messaging";
import eventReportService from '../services/event-report.js';

export default function Join({setNavbarState, navbarState}) {
  const [redirect, setRedirect] = React.useState({ trigger: false, route: '/' })
  const [loadingPreview, setLoadingPreview] = React.useState(true)
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [notificationsEnabled, setNotificationsEnabled] = React.useState(AppConfig.getLocal('notification_enable'));
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [userData, setUserData] = React.useState([]);
  const [formData, setFormData] = React.useState({
    name: userData.name,
    lastname: userData.lastname,
    phone: userData.phone,
  });

  const userCors = process.env.REACT_APP_USE_CORS;
  if ( userCors === "true") {
    axios.defaults.withCredentials = true
  }

  const apiUrl = process.env.REACT_APP_MGO_API_URL

  React.useEffect(() => {
    setNavbarState({...navbarState, showBack: false, showOptions: false, showSearch: false})
    axios.get(`${apiUrl}/users/me`)
      .then((response) => {
        AppConfig.parseResponse(response);
        if (response.status === 200) {
          setUserData(response.data.data);
          setLoadingPreview(false);
        } else if (response.status === 401) {
          setRedirect({ trigger: true, route: '/login' });
        }
      })
      .catch((error) => {
        setRedirect({ trigger: true, route: '/login' });
      })
  }, []);

  const handleSave = React.useCallback(() => {
    setLoadingPreview(true);
      axios
        .post(`${apiUrl}/users/update/me`, formData)
        .then((response) => {
          // Handle the response as needed
          AppConfig.parseResponse(response);
          if (response.status === 200) {
            setRedirect({ trigger: true, route: '/?next=/perfil' })
          } else {
            // Handle errors
          }
        })
        .catch((error) => {
          setSnackbarMessage(error);
          setSnackbarOpen(true);
          setLoadingPreview(false);
        });
    }, [apiUrl, formData]);  
  
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  const handleNotificationToggle = (event) => {
    if (event.target.checked) {
      requestNotificationPermission();
    } else {
      unregisterNotifications();
    }
  }

  const requestNotificationPermission = () => {
    if ('Notification' in window) {
      Notification.requestPermission()
        .then((permission) => {
          if (permission === 'granted') {
            console.log('Notification permission granted');
            eventReportService('notification-enabled', { device: navigator.userAgent })
            registerToken();
          } else if (permission === 'denied') {
            console.log('Notification permission denied');
          }
        })
        .catch((error) => {
          console.error('Error requesting notification permission:', error);
        });
    } else {
      console.log('Notifications not supported in this browser');
    }
  };

  const unregisterNotifications = async () => {
    axios.post(`${apiUrl}/users/notification/unregister`,{token_id: AppConfig.getLocal('notification_token_id')})
    .then((response) => {
      AppConfig.parseResponse(response);
      if (response.status === 200) {
        setNotificationsEnabled(false);
        AppConfig.storeLocal('notification_enable', false);
      }})
      .catch((error) => {
        console.error('Error removing notification token:', error);
        setNotificationsEnabled(false);
        AppConfig.storeLocal('notification_enable', false);
      });
    }

  const registerToken = async () => {
    setLoadingPreview(true);
    const messaging = getMessaging();
    const token = await getToken(messaging, { vapidKey: process.env.REACT_APP_VAPID_KEY });
    AppConfig.storeLocal('notification_token_id', token.slice(-6));
    axios
      .post(`${apiUrl}/users/notification/register`, { token: token, platform: AppConfig.getDevicePlatform(), device: navigator.userAgent})
      .then((response) => {
        AppConfig.parseResponse(response);
        if (response.status === 200) {
          AppConfig.storeLocal('notification_enable', true);
          setNotificationsEnabled(true);
          console.log('Token registered');
          setLoadingPreview(false);
        } else {
          setLoadingPreview(false);
        }
      })
      .catch((error) => {
        setSnackbarMessage(error);
        setSnackbarOpen(true);
      });
  }
  
  const renderUnit = () => {
    if (userData.role === "receptor" || userData.role === "admin") {
      return "Unidad"
    } else {
      return AppConfig.get('unit_type')
    }
  }

  return (
    <Container maxWidth="sm">
      {redirect.trigger && (
        <Navigate to={redirect.route} replace={true} />
      )}
      <Snackbar open={snackbarOpen} autoHideDuration={3000} onClose={handleClose} anchorOrigin={{ vertical: "top", horizontal: "center" }} sx={{mt: "3.2rem"}}>
        <Alert onClose={handleClose} severity="warning" sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
      <Box sx={{ ml: 0 }}>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loadingPreview}
        >
          <CircularProgress color="inherit" />
        </Backdrop>

        {!loadingPreview &&
          (<Box maxWidth={true} display="flex" flexDirection="column" sx={{ pt: 1 }}>
            <Box display="flex" alignItems="center" flexDirection="column" sx={{ pt: 1 }}>
              <Typography gutterBottom variant="h5" component="div" sx={{ mb: 4 }}>
                Mi Perfil
              </Typography>
            </Box>
            <Box display="flex" alignItems="left" flexDirection="column">
              <TextField size="medium" type='text' id='name' defaultValue={userData.name} sx={{ mb: 2 }} label="Nombre" variant="outlined" onChange={(e) => setFormData({ ...formData, name: e.target.value })} />
              <TextField size="medium" type='text' id='lastname' defaultValue={userData.lastname} sx={{ mb: 2 }} label="Apellido" variant="outlined" onChange={(e) => setFormData({ ...formData, lastname: e.target.value })} />
              <TextField size="medium" type='number' id='phone' defaultValue={userData.phone} sx={{ mb: 2 }} label="Numero Telefonico" variant="outlined" onChange={(e) => setFormData({ ...formData, phone: e.target.value })} />
              {userData.role !== "receptor" ? (<FormControlLabel
                control={<Switch defaultChecked={userData.appear_in_directory} onChange={(e) => setFormData({ ...formData, appear_in_directory: e.target.checked })} name="appearInDirectory" />}
                label="Aparecer en Directorio"
                sx={{ mb: 2, ml: 1 }}
              />): ''}
              <FormControlLabel
                control={
                  <Switch
                    checked={notificationsEnabled}
                    onChange={handleNotificationToggle}
                    name="receiveNotifications"
                  />
                }
                label="Recibir Notificaciones"
                sx={{ mb: 2, ml: 1 }}
              />
              {AuthService.userRoleIncludes([]) && notificationsEnabled  && (
                <Typography variant="caption" sx={{ mt: -2, ml: 3, mb: 1 }} color="gray">
                  {AppConfig.getLocal('notification_token_id')}
                </Typography>
              )}
              <Divider variant="middle" sx={{ mb: 4 }}/>
              <TextField disabled size="medium" type='mail' id='mail' defaultValue={userData.username} sx={{ mb: 1.2 }} label="Correo Electronico" variant="outlined" helperText="*Contacte al administrador para cambiar su correo."/>
              <TextField disabled defaultValue={userData.unit} size="medium" type='text' id='apartment' sx={{ mb: 1.2 }} label={renderUnit()} variant="outlined" />
              
            </Box>
            <Box display="flex" alignItems="center" flexDirection="column" sx={{ pt: 1, mt: 3 }}>
              <Button fullWidth size="large" color="secondary" variant="contained" onClick={handleSave} sx={{py: 1.4}}>Guardar</Button>
            </Box>
          </Box>
          )
        }
      </Box>
    <BackgroundImage />
    </Container>
  );
}
