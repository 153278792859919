import * as React from 'react';
import * as ReactDOM from 'react-dom/client';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { BrowserRouter, Routes, Route, Navigate, useLocation } from "react-router-dom";
import Login from './pages/Login';
import Visits from './pages/Visits';
import ShareCode from './pages/ShareCode';
import Home from './pages/Home'
import Camera from './pages/Camera'
import Reception from './pages/Reception'
import Profile from './pages/Profile'
import Register from './pages/Register'
import Directory from './pages/Directory'
import UserAdmin from './pages/UserAdmin'
import Activity from './pages/Activity'
import ReportError from './pages/ReportError'
import PasswordReset from './pages/PasswordReset'
import theme from './theme';
import Navbar from './components/Navbar';
import Amenities from './pages/Amenities';
import Amenity from './pages/Amenity';
import Reservation from './pages/Reservation';
import Approvals from './pages/Approvals';
import IssueReport from './pages/IssueReport';
import Issue from './pages/Issue';
import NewReport from './pages/NewReport';
import QRCode from './pages/QRCode';
import AllReservations from './pages/AllReservations';
import ImgUpload from './pages/ImgUploadSU';
import AmenityAdmin from './pages/AmenityAdmin';
import "./App.css"


import { initializeApp } from "firebase/app";
import ResidentHub from './pages/ResidentHub';
import AdminHub from './pages/AdminHub';

const rootElement = document.getElementById('root');
const root = ReactDOM.createRoot(rootElement);

function App() {
  const firebaseConfig = {
    apiKey: "AIzaSyANkG9-gqHdhK5byRpIxJUKPWMHcHCBsZ4",
    authDomain: "migaritaonline.firebaseapp.com",
    projectId: "migaritaonline",
    storageBucket: "migaritaonline.appspot.com",
    messagingSenderId: "279037079450",
    appId: "1:279037079450:web:671127ed54558d9b3f1423",
    measurementId: "G-9S0D4WWFQJ"
  };
  const app = initializeApp(firebaseConfig);
  var last_path = null;

  const getPath = () => {
    const location = useLocation();
    return location.pathname
  }

  const navbarOptions = {
    showSeach: false,
    showOptions: false,
    showBack: false,
    pageName: "",
    options: []
  }

  const [navbarState, setNavbarState] = React.useState(navbarOptions);
  const [history,setHistory] = React.useState([]);

  function RouteChangeHandler({setNavbarState}) {
    const location = useLocation();
    if (last_path === location.pathname) {
      return null;
    }
    if  (last_path !== null) {
      console.log("Route changed to:", location.pathname);
      setNavbarState(navbarOptions);
    }
    last_path = location.pathname;
    return null; // This component doesn't render anything
  }

  return(
    <ThemeProvider theme={theme}>
      {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
      <CssBaseline />
      <BrowserRouter>
      <RouteChangeHandler setNavbarState={setNavbarState}/>
      <Navbar getPath={getPath} navbarState={navbarState} setNavbarState={setNavbarState} history={history} setHistory={setHistory}/>
        <Routes>
            <Route index element={<Home />} />
            <Route path="/compartir" element={<ShareCode setNavbarState={setNavbarState} navbarState={navbarState}/>} />
            <Route path="/login" element={<Login setNavbarState={setNavbarState} navbarState={navbarState}/>}/>
            <Route path="/visitas" element={<Visits setNavbarState={setNavbarState} navbarState={navbarState}/>}/>
            <Route path="/qr" element={<QRCode setNavbarState={setNavbarState} navbarState={navbarState}/>}/>
            <Route path="/recepcion" element={<Reception setNavbarState={setNavbarState} navbarState={navbarState}/>}/>
            <Route path="/perfil" element={<Profile setNavbarState={setNavbarState} navbarState={navbarState}/>}/>
            <Route path="/registro" element={<Register setNavbarState={setNavbarState} navbarState={navbarState}/>}/>
            <Route path="/directorio" element={<Directory setNavbarState={setNavbarState} navbarState={navbarState}/>}/>
            <Route path="/useradmin" element={<UserAdmin setNavbarState={setNavbarState} navbarState={navbarState}/>}/>
            <Route path="/nuevoreporte" element={<NewReport setNavbarState={setNavbarState} navbarState={navbarState}/>}/>
            <Route path="/actividad" element={<Activity setNavbarState={setNavbarState} navbarState={navbarState}/>}/>
            <Route path="/pwdreset" element={<PasswordReset setNavbarState={setNavbarState} navbarState={navbarState}/>}/>
            <Route path="/camara" element={<Camera setNavbarState={setNavbarState} navbarState={navbarState}/>}/>
            <Route path="/amenidades" element={<Amenities setNavbarState={setNavbarState} navbarState={navbarState}/>}/>
            <Route path="/amenidad" element={<Amenity setNavbarState={setNavbarState} navbarState={navbarState}/>}/>
            <Route path="/reservacion" element={<Reservation setNavbarState={setNavbarState} navbarState={navbarState}/>}/>
            <Route path="/reservaciones" element={<AllReservations setNavbarState={setNavbarState} navbarState={navbarState}/>}/>
            <Route path="/aprobaciones" element={<Approvals setNavbarState={setNavbarState} navbarState={navbarState}/>}/>
            <Route path="/reportes" element={<IssueReport setNavbarState={setNavbarState} navbarState={navbarState}/>}/>
            <Route path="/reporte" element={<Issue setNavbarState={setNavbarState} navbarState={navbarState}/>}/>
            <Route path="/residenthub" element={<ResidentHub setNavbarState={setNavbarState} navbarState={navbarState}/>}/>
            <Route path="/imgupload" element={<ImgUpload setNavbarState={setNavbarState} navbarState={navbarState}/>}/>
            <Route path="/amenityadmin" element={<AmenityAdmin setNavbarState={setNavbarState} navbarState={navbarState}/>}/>
            <Route path="/adminhub" element={<AdminHub setNavbarState={setNavbarState} navbarState={navbarState}/>}/>
            <Route path="*" element={<Home />} />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}

root.render(<App />);
