import * as React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import Fab from '@mui/material/Fab';

import axios, { all } from 'axios';

import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import TextField from '@mui/material/TextField';

import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import DirectionsWalkIcon from '@mui/icons-material/DirectionsWalk';

import Button from '@mui/material/Button';
import { Navigate } from 'react-router-dom';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';

import CheckBoxRoundedIcon from '@mui/icons-material/CheckBoxRounded';
import LockClockIcon from '@mui/icons-material/LockClock';
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';

import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';

import AppConfig from '../services/app-config';
import BackgroundImage from '../components/Cityline';

import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Autocomplete from '@mui/material/Autocomplete';

export default function Lists({window,setNavbarState, navbarState}) {
  const apiUrl = process.env.REACT_APP_MGO_API_URL;
  const [lists, setLists] = React.useState([]);
  const [searchValue, setSearchValue] = React.useState('');
  const [redirect, setRedirect] = React.useState({ trigger: false, route: '/' });
  const [addListDrawer, setAddListDrawer] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [visitorName, setVisitorName] = React.useState('');
  const [walking, setWalking] = React.useState(false);
  const [vehicle, setVehicle] = React.useState(false);
  const [vehiclePlate, setVehiclePlate] = React.useState("");
  const [snackbar, setSnackbar] = React.useState({ open: false, message: '', severity: 'success' });
  const [tabValue, setTabValue] = React.useState('active');
  const [tabValueFriendly, setTabValueFriendly] = React.useState('vigente');
  const [expanded, setExpanded] = React.useState(false);
  const [rules,setRules] = React.useState({"vehicle": true,walking: true,default_lifespan_hours: 24,allow_multiple_entries: true,allow_longer_lifespan: true,max_lifespan_hours: 168,days_array: ["1 Dia"]});
  const [showAdvanceOpts,setShowAdvanceOpts] = React.useState(false);
  const [advanceOpts,setAddvanceOpts] = React.useState({"allow_multiple_entries": false,"max_lifespan_days": 1});
  const [nameSuggestions, setNameSuggestions] = React.useState([]);

  const userCors = process.env.REACT_APP_USE_CORS;
  if ( userCors === "true") {
    axios.defaults.withCredentials = true
  }

  const filteredCodes = lists.filter(code =>
    code.nombre.toLowerCase().includes(searchValue.toLowerCase())
    || code.codigo.toLowerCase().includes(searchValue.toLowerCase())
  );

  const fabStyle = {
    position: 'fixed',
    bottom: 32,
    right: 32
  };

  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
  };

  const handleTabChange = (event,newValue) => {
    if (newValue === "active") {
      setTabValueFriendly('vigente')
    } else {
      setTabValueFriendly('vencido')
    }
    setTabValue(newValue);
    setLoading(true)
    fetchVisits(newValue);
  }

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  const handleAddAccess = () => {
    if(visitorName.trim() === '' || (!walking && !vehicle)){
        setSnackbar({ open: true, message: 'Ingrese el nombre y asegurese de marcar el tipo de acceso.', severity: 'warning' });
        return;
    }
    if(visitorName.split(" ").length < 2){
        setSnackbar({ open: true, message: 'Por favor agregar al menos un nombre y un apellido.', severity: 'warning' });
        return;
    }

    if(vehicle && vehiclePlate === null && AppConfig.get("force_resident_vehicle_plate_input")){
        setSnackbar({ open: true, message: 'Por favor ingrese la placa del vehiculo.', severity: 'warning' });
        return;
    }

    // Check if the visitor name already exists in active visits
    const existingActiveVisit = lists.find(visit => 
      visit.nombre.toLowerCase() === visitorName.toLowerCase() && 
      visit.estado.toLowerCase() === 'vigente'
    );

    if (existingActiveVisit) {
      setSnackbar({ open: true, message: 'Ya existe un acceso activo para este visitante.', severity: 'warning' });
      return;
    }

    const data = {
      visitor_name: visitorName,
      walking,
      vehicle,
      vehicle_plate: vehiclePlate,
      max_lifespan_days: advanceOpts.max_lifespan_days,
      allow_multiple_entries: advanceOpts.allow_multiple_entries
    };
    setLoading(true)
    axios.post(`${apiUrl}/visit/create`, data)
      .then((response) => {
        AppConfig.parseResponse(response);
        if(response.status === 200) {
          setSnackbar({ open: true, message: 'Acceso Creado Exitosamente', severity: 'success' });
          setVisitorName('');
          setWalking(false);
          setVehicle(false);
          setRedirect({ trigger: true, route: `/compartir?code=${response.data.data.code}` });
        } else if (response.status === 401) {
          setRedirect({ trigger: true, route: '/login' });
        }
        handleDrawerToggle()
        fetchVisits(tabValue)
      })
      .catch(error => {
        setSnackbar({ open: true, message: 'Error al crear acceso, intente de nuevo en un momento.', severity: 'error' });
        console.error(error);
        handleDrawerToggle()
      })
  }

  const handleVisitorNameChange = (event) => {
    setVisitorName(event.target.value);
  };
  const handleWalkingSwitchChange = (event) => {
    setWalking(event.target.checked);
    if (event.target.checked) {
      setVehicle(false);
    }
  };
  const handleVehicleSwitchChange = (event) => {
    setVehicle(event.target.checked);
    if (event.target.checked) {
      setWalking(false);
    }
  };

  const handleVehiclePlateChange = (event) => {
    setVehiclePlate(event.target.value.toUpperCase());
  };

  const deleteAccess = (item) => {
    const data = {
      "access_code": item.codigo
    }
    setLoading(true)
    axios.post(`${apiUrl}/delete/visit`, data)
      .then((response) => {
        AppConfig.parseResponse(response);
        if(response.status === 200) {
          fetchVisits(tabValue)
          setLoading(false)
          setSnackbar({ open: true, message: 'Acceso Eliminado', severity: 'success' });
          
        } else if (response.status === 401) {
          setRedirect({ trigger: true, route: '/login' });
        }
      })
      .catch(error => {
        setSnackbar({ open: true, message: 'Error al crear acceso, intente de nuevo en un momento.', severity: 'error' });
        console.error(error);
        handleDrawerToggle()
      })
  }

  const openAccess = (item) => { 
    if (item.estado.toLowerCase() !== "vencido"){
      setRedirect({ trigger: true, route: `/compartir?code=${item.codigo}` })
    } else {
      setSnackbar({ open: true, message: 'El acceso ha vencido, no se puede abrir.', severity: 'warning' })
    }
  }

  const fetchVisits = (type) => {
    axios.get(`${apiUrl}/my/visits/${type}`)
      .then((response) => {
        AppConfig.parseResponse(response);
        if (response.status === 200) {
          const cts = Math.round(new Date().getTime() / 1000);
          const allLists = response.data.data.reverse()
          setLists(allLists)
          setLoading(false)
        } else if (response.status === 401) {
          setRedirect({ trigger: true, route: '/login' });
        }
      })
      .catch(error => {
        console.log(error)
        if (error.response.data && error.response.data.code === 401) {
          setRedirect({ trigger: true, route: '/login' })
        }
      })
  }

  const fetchNameSuggestions = () => {
    axios.get(`${apiUrl}/suggestions/visits/names`)
      .then((response) => {
        AppConfig.parseResponse(response);
        if (response.status === 200) {
          const suggestions = Array.isArray(response.data.data) ? response.data.data : [];
          setNameSuggestions(suggestions);
        }
      })
      .catch(error => {
        console.error('Error fetching name suggestions:', error);
        setNameSuggestions([]);
      });
  };

  const renderPage = () => {
    if (filteredCodes.length > 0) {
      return (filteredCodes.map((item) => (
        <Card variant="outlined" key={item.codigo} style={{ marginBottom: '0.5rem', borderRadius: '10px' }} onClick={tabValue === "active" ? (event => openAccess(item)) : null}>
            
            {(() => {
                switch (tabValue) {
                  case 'active':
                    return (<a onClick={event => openAccess(item)} style={{ float: 'right', color: 'inherit', textDecoration: 'none', marginTop: "0.8rem", marginRight: "0.8rem" }}>
                    <ArrowForwardIcon color="secondary" />
                  </a>)
                  case 'expired':
                    return (
                      <a onClick={event => deleteAccess(item)} style={{ float: 'right', color: 'inherit', textDecoration: 'none', marginTop: "0.8rem", marginRight: "0.8rem" }}>
                      <DeleteSweepIcon color="secondary" />
                    </a>
                    )
                }
              })()}
          <CardContent style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row', alignItems: 'center' }}>
            <div style={{ display: 'flex' }}>
              {(() => {
                switch (item.estado.toLowerCase()) {
                  case 'vigente':
                    return <CheckBoxRoundedIcon color='secondary' sx={{mt: 0.5}}/>
                    case 'utilizado':
                      return <CheckBoxRoundedIcon color='default' sx={{mt: 0.5}}/>
                  case 'vencido':
                    return <LockClockIcon color='default' sx={{mt: 0.5}}/>
                  default:
                    return <LockClockIcon color='default' sx={{mt: 0.5}}/>
                }
              })()}
              <div>
                <Box sx={{ml: 2}}>
                
                  <Typography variant="h5" component="div">
                    <b>{item.nombre}</b>{item.placa ? <DirectionsCarIcon sx={{ml: 0.8}}/> : <DirectionsWalkIcon sx={{ml: 0.5}}/>}
                  </Typography>
                  <Typography variant="h5" component="div">
                    
                  </Typography>
                  <Typography variant="body2" color="text.secondary"><b>Codigo:</b> {item.codigo}</Typography>
                  <Typography variant="body2" color="text.secondary"><b>Estado:</b> {item.estado}</Typography>
                  {item.placa ? <Typography variant="body2" color="text.secondary"><b>Placa:</b> {item.placa}</Typography> : ""}

                {(() => {
                  switch (item.estado.toLowerCase()) {
                    case 'vigente':
                      return <Typography variant="body2" color="text.secondary"><b>Emitido:</b> {AppConfig.formatFriendlyDate(item.fecha,true)}</Typography>
                    case 'vencido':
                      return <Typography variant="body2" color="text.secondary"><b>Utilizado:</b> {AppConfig.formatFriendlyDate(item.fecha,true)}</Typography>
                      case 'utilizado':
                        return <Typography variant="body2" color="text.secondary"><b>Utilizado:</b> {AppConfig.formatFriendlyDate(item.fecha,true)}</Typography>
                    default:
                      return null;
                  }
                })()}
                </Box>
              </div>
            </div>
          </CardContent>
        </Card>
      )))
    } else {
      return (
        <Card variant="outlined" style={{ marginBottom: '0.5rem', borderRadius: '10px', textAlign: 'center' }}>
          <CardContent>
            <Typography variant="h5" component="div" sx={{ mt: 2 ,mb: 4}}><b>No tienes ningun acceso {tabValueFriendly}.</b></Typography>
            <Divider />
            <Typography variant="body1" color="text.secondary" sx={{ mb: 2, mt: 4}}>Presiona 'Nueva Visita' para crear un acceso.</Typography>
          </CardContent>
        </Card>
      )
    }
  }

  const handleDrawerToggle = () => {
    setAddListDrawer((prevState) => !prevState);
  };

  const newVisitHandler = () => {
    setLoading(true);

    axios.get(`${apiUrl}/visit/rules`)
      .then((response) => {
        AppConfig.parseResponse(response);
        if (response.status === 200) {
          let lifespan_days = response.data.data.max_lifespan_hours / 24;
          let days_array = [];
          for (let day = 1; day <= lifespan_days; day++) {
            let word_day = "Dias"
            if (day === 1) { word_day = "Dia" }
            days_array.push(`${day} ${word_day}`)
          }
          setRules({...response.data.data,days_array: days_array});
          setShowAdvanceOpts(response.data.data.allow_longer_lifespan || response.data.data.default.allow_multiple_entries);
          setExpanded(false);
          handleDrawerToggle();
          setLoading(false);
        } else if (response.status === 401) {
          setRedirect({ trigger: true, route: '/login' });
        }
      })
      .catch(error => {
        console.log(error)
        setLoading(false)
        if (error.response.data && error.response.data.code === 401) {
          setRedirect({ trigger: true, route: '/login' })
        } else {
          setSnackbar({ open: true, message: 'Error al obtener los detalles para crear accesos, intente de nuevo en unos momentos.', severity: 'error' });
        }

      })
    
  };

  React.useEffect(() => {
    fetchVisits(tabValue)
    fetchNameSuggestions();
    setNavbarState({ ...navbarState, showBack: true, showOptions: false, showSearch: false})
  }, [])

  const drawer = (
    <Card
      sx={{ py: 3, px: 2, borderTopLeftRadius: "20px", borderTopRightRadius: "20px" }}>
      <CardContent>
        <Typography gutterBottom variant="h5" component="div" sx={{ mb: 3 }}>
          Crear Acceso
        </Typography>
        <Autocomplete
          freeSolo
          options={nameSuggestions}
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              size="medium"
              id='visitorName'
              sx={{ textAlign: 'center', mb: 2 }}
              label="Nombre completo de tu visita"
              variant="outlined"
            />
          )}
          onInputChange={(event, newValue) => {
            setVisitorName(newValue);
          }}
          renderOption={(props, option, state) => {
            // Only render the first 5 matching options
            if (state.index < 5) {
              return (
                <li {...props} key={option}>
                  {option}
                </li>
              );
            }
            return null;
          }}
        />
        <FormGroup>
          {rules.walking && <FormControlLabel control={<Switch checked={walking} onChange={handleWalkingSwitchChange} />} sx={{ mb: 1 }} label="Acceso Peatonal" />}
          {rules.vehicle && <FormControlLabel control={<Switch checked={vehicle} onChange={handleVehicleSwitchChange} />} sx={{ mb: 1 }} label="Acceso Vehicular" />}
        </FormGroup>
        {vehicle ? <TextField size="medium" id='vehiclePlate' sx={{ textAlign: 'center', mb: 2 }} label="Placa del vehiculo" value={vehiclePlate} variant="outlined" onChange={handleVehiclePlateChange} /> : ''}
      
        {showAdvanceOpts && (
          <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')} disabled={!showAdvanceOpts}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography sx={{ flexShrink: 0 }}>
                Opciones Avanzadas
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                {rules.allow_longer_lifespan && (
                  <FormControl fullWidth variant="outlined">
                    <InputLabel id="days-select-label">Validez</InputLabel>
                    <Select
                      labelId="days-select-label"
                      id="days-select"
                      value={advanceOpts.max_lifespan_days}
                      label="Validez"
                      onChange={(event) => setAddvanceOpts({allow_multiple_entries: event.target.value != 1, max_lifespan_days: event.target.value})}
                    >
                      {rules.days_array.map((day, index) => (
                        <MenuItem key={index} value={index+1}>{day}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
                {advanceOpts.max_lifespan_days === 1 && (
                  <FormControlLabel
                    control={
                      <Switch
                        checked={advanceOpts.allow_multiple_entries}
                        onChange={() => setAddvanceOpts({...advanceOpts, allow_multiple_entries: !advanceOpts.allow_multiple_entries})}
                      />
                    }
                    label="Permitir Multiples Accesos"
                  />
                )}
              </Box>
            </AccordionDetails>
          </Accordion>
        )}
      </CardContent>
      <CardActions>
        <Button size="medium" onClick={handleDrawerToggle} color="inherit" variant="outlined">Cancelar</Button>
        <Button size="medium" onClick={event => handleAddAccess(event)} color="secondary" variant="contained">Crear</Button>
      </CardActions>
    </Card>
  );

  const container = window !== undefined ? () => window().document.body : undefined;
  return (
    <Container maxWidth="sm">
      {redirect.trigger && (
        <Navigate to={redirect.route} replace={true} />
      )}
      <Snackbar open={snackbar.open} autoHideDuration={3000} anchorOrigin={{ vertical: "top", horizontal: "center" }} sx={{mt: "3.2rem"}} onClose={handleSnackbarClose}>
        <Alert onClose={handleSnackbarClose} severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>
      <Box sx={{ ml: 0 }}>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        {lists.length > 4 && (
          <TextField 
            fullWidth 
            size="medium" 
            id='search' 
            sx={{ 
              textAlign: 'center', 
              mb: 0, 
              mt: 2,
            }} 
            label="Busqueda" 
            variant="outlined" 
            onChange={handleSearchChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
              style: { borderRadius: '8px'}
            }}
          />
        )}
          <Tabs value={tabValue } onChange={handleTabChange} aria-label="user tabs" variant="fullWidth" sx={{ mt: 0, mb: 0 }} >
            <Tab label="Vigentes" value={'active'}/>
            <Tab label="Vencidos" value={'expired'}/>
          </Tabs>
        {!loading &&
          (<List>
            {renderPage()}
          </List>)}
      </Box>
      <Box sx={{ m: 1 }}>
        <Box sx={{ m: 1, display: 'flex', justifyContent: 'center' }}>
          <Fab sx={fabStyle} variant="extended" color="secondary" size="large" aria-label="add" onClick={newVisitHandler}>
            <EmojiPeopleIcon sx={{ mr: 1 }} />
            Nueva Visita
          </Fab>
        </Box>
        <Drawer
          container={container}
          anchor="bottom"
          variant="temporary"
          open={addListDrawer}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          PaperProps={{ elevation: 0, style: { backgroundColor: "transparent" } }}
          sx={{
            display: { xs: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: '440' },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
    <BackgroundImage />
    </Container>
  );
}
