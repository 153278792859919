import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ContactsIcon from '@mui/icons-material/Contacts';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import Snackbar from '@mui/material/Snackbar';
import CloseIcon from '@mui/icons-material/Close';
import FlakyIcon from '@mui/icons-material/Flaky';
import Button from '@mui/material/Button';

import Divider from '@mui/material/Divider';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';

import AuthService from '../services/auth';
import LoginIcon from '@mui/icons-material/Login';
import LogoutIcon from '@mui/icons-material/Logout';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';
import DeskIcon from '@mui/icons-material/Desk';
import HistoryIcon from '@mui/icons-material/History';
import ChairRoundedIcon from '@mui/icons-material/ChairRounded';
import HomeIcon from '@mui/icons-material/Home';
import ReportIcon from '@mui/icons-material/Report';
import DashboardIcon from '@mui/icons-material/Dashboard';


import axios from 'axios';

import { Navigate } from 'react-router-dom';

import AppConfig from '../services/app-config';
import InstallPrompt from '../components/InstallPrompt';

const apiUrl = process.env.REACT_APP_MGO_API_URL;
const userCors = process.env.REACT_APP_USE_CORS;
  if ( userCors === "true") {
    axios.defaults.withCredentials = true
  }

const drawerWidth = 280;
const navItems = [
  { text: 'Inicio', icon: <HomeIcon />, showTo: ["habitante"],link: "residenthub"},
  { text: 'Admin Panel', icon: <DashboardIcon />, showTo: ["admin"],link: "adminhub"},
  { text: 'Perfil', icon: <AccountCircleIcon />, showTo: ["habitante","receptor","admin"]},
  { text: 'Visitas', icon: <EmojiPeopleIcon />, showTo: ["habitante"] , needsModule: ["visits"]},
  { text: 'Recepcion', icon: <DeskIcon />, showTo: ["receptor","admin"] , needsModule: ["visits"]},
  { text: 'Reportar Avería', icon: <ReportIcon />, showTo: ["habitante","receptor","admin"], link: "reportes", needsModule: ["issues"]},
  { text: 'Amenidades', icon: <ChairRoundedIcon />, showTo: ["habitante","admin"] , needsModule: ["amenities"]},
  { text: 'Residentes', icon: <ContactsIcon />, showTo: ["admin","receptor"], link: "directorio" },
  { text: 'Directorio', icon: <ContactsIcon />, showTo: ["habitante"], conditionalConfig: "appear_in_directory"},
  { text: 'Admin. de Usuarios', icon: <AdminPanelSettingsIcon />, showTo: ["admin"], link: "useradmin" },
  { text: 'Actividad', icon: <HistoryIcon />, showTo: ["receptor","admin"]},
  { text: 'Aprobaciones', icon: <FlakyIcon />, showTo: ["admin"], needsModule: ["amenities"]},
];

const snackBarMessages = {
  "/directorio": { message: "Si no deseas aparecer en el directorio de vecinos, puedes desactivarlo en tu perfil.",
    duration: 15000,
    condition: "directory_fist_time",
    roles: ["habitante"]
  },
  // "/amenidades": { message: "Puedes ver tus reservaciones usando la pestaña superior derecha.",
  //   duration: 8000,  
  //   condition: "amenities_first_time",
  //   roles: ["habitante"]
  // },
  "/nuevoreporte": { message: "Para evitar reportes duplicados, te recomendamos revisar si ya existe un reporte activo antes de enviar uno nuevo.",
    duration: 15000,  
    condition: "new_report_first_time",
    roles: ["habitante"]
  },
  "/visitas": { message: "Si quieres recibir notificaciones sobre tus visitas, reportes o reservas, puedes activar las notificaciones en tu perfil.",
    duration: 10000,
    precondition: { key: "notification_enable", value: false },
    condition: "visits_first_time",
    roles: ["habitante"]
  },
  "/reporte": { message: "Si quieres recibir notificaciones sobre los reportes, puedes activar las notificaciones en tu perfil.",
    duration: 9000,
    precondition: { key: "notification_enable", value: false },
    condition: "issues_first_time",
    roles: ["habitante"]
  }
}


const Offset = styled('div')(({ theme }) => theme.mixins.toolbar);


export default function Navbar({getPath,navbarState,setNavbarState,setHistory,history,window}) {
  const path = getPath()
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [showOptions, setShowOptions] = React.useState(false);
  const [userName, setUserName] = React.useState("Usuario");
  const [userUnit, setUserUnit] = React.useState("Unidad");
  const [userRole, setUserRole] = React.useState("Role");
  const [userOrg, setUserOrg] = React.useState("Org");
  const [snackbar, setSnackbar] = React.useState({open: false, message: "", duration: 3000});
  const [redirect, setRedirect] = React.useState({ trigger: false, route: '/' })

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };
  const handleNavClick = (route) => {
    setRedirect({ trigger: true, route: `/${route.toLowerCase()}` })
  }

  const handleLogout = () => {
    unregisterNotifications()
  }

  const unregisterNotifications = async () => {
    if (AppConfig.getLocal('notification_token_id')) {
      axios.post(`${apiUrl}/users/notification/unregister`,{token_id: AppConfig.getLocal('notification_token_id')})
      .then((response) => {
        AppConfig.parseResponse(response);
        if (response.status === 200) {
          AuthService.logout()
          setRedirect({trigger: true, route: '/login'})
        }})
        .catch((error) => {
          console.error('Error removing notification token:', error);
          AuthService.logout()
          setRedirect({trigger: true, route: '/login'})
        });
      } else {
      AuthService.logout()
      setRedirect({trigger: true, route: '/login'})
    }
  }

  const isSelected = (menuItem) => {
    if (menuItem.link) {
      return `/${menuItem.link.toLowerCase()}` === path
    }
    return `/${menuItem.text.toLowerCase()}` === path
  }

  const genHeader = () => {
    switch (path) {
      case '/':
        return 'Inicio';
      case '/residenthub':
        return 'Inicio';
      case '/adminhub':
        return 'Admin Panel';
      case '/useradmin':
        return 'Admin. de Usuarios';
      case '/pwdreset':
        return 'Restablecer Contraseña';
      case '/login':
        return 'Iniciar Sesion';
      case '/nuevoreporte':
        return 'Nuevo Reporte';
      case '/qr':
        return 'Escaner QR';
      case '/amenityadmin':
        return 'Admin. de Amenidades';
      default:
        return path
          .substring(1)
          .split('/')
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join('');
    }
  };

  const handleBackClick = () => {
    setRedirect({ trigger: true, route: history[history.length - 2]})
    setHistory(prevHistory => prevHistory.slice(0, -1))
  }

  React.useEffect(() => {
    setUserName(AuthService.getCurrentUser())
    setUserUnit(AuthService.getCurrentUnit())
    setUserRole(AuthService.getCurrentUserRole())
    setUserOrg(AuthService.getCurrentUserOrg())
    setHistory(prevHistory => [...prevHistory.slice(-7), path])

    if (path in snackBarMessages) {
      const snackbarMessage = snackBarMessages[path];
      const shouldShowSnackbar = !AppConfig.getLocal(snackbarMessage.condition);
      if (snackBarMessages[path].precondition) {
        const precondition_value = AppConfig.getLocal(snackBarMessages[path].precondition.key);
        if (precondition_value !== snackBarMessages[path].precondition.value) {
          return
        }
      }
      if (shouldShowSnackbar && AuthService.userRoleIncludes(snackbarMessage.roles)) {
        AppConfig.storeLocal(snackbarMessage.condition, true);
        setSnackbar({
          open: true,
          message: snackbarMessage.message,
          duration: snackbarMessage.duration
        });
      }
    }
  }, [userName, userUnit, userRole, userOrg, path, window])

  if (redirect.trigger) {
    if (redirect.route === path) {
      setRedirect({ trigger: false, route: '/' })
    }
  }

  

  const drawer = (
    <>
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center', mt: 2, overflowY: 'auto', maxHeight: 'calc(100vh - 13vh)' }}>
      <img src="/logo.svg" alt="Logo" style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto', width: '20%' }} />
      <Typography variant="h6" sx={{ mt: 2, textAlign: 'center' }}>
        {userName}
      </Typography>
      <Typography variant="body2" sx={{ color: 'gray', fontStyle: 'italic', textAlign: 'center' }}>
        {userOrg}: {userUnit}
      </Typography>
      <Divider sx={{ mt: 2 }} />
      <List>
      {navItems.map((item) => {
        if (item.conditionalConfig) {
          const configValue = AppConfig.get(item.conditionalConfig);
          if (!configValue) {
            console.log(`skipping ${item.text}`)
            return null;
          }
        }
        if (item.needsModule) {
          let meetsModules = true
          for (const module of item.needsModule) {
            if (!AppConfig.get("modules").includes(module)) {
              meetsModules = false;
              break;
            }
          }
          if (!meetsModules) {
            return null;
          }
        }
        return AuthService.userRoleIncludes(item.showTo) &&
          <ListItem key={item.text} disablePadding>
            <ListItemButton selected={isSelected(item)} onClick={() => handleNavClick(item.link ?? item.text)} sx={{ textAlign: 'center' }}>
              <ListItemText primary={item.text} />
              {item.icon}
            </ListItemButton>
          </ListItem>;
      })}     
      </List>
      
    </Box>
    <Box onClick={handleDrawerToggle} sx={{ position: 'fixed', bottom: 2, textAlign: 'center', width: drawerWidth }}>
      <List>          
        {AuthService.isLogged() ?
          (<>
          <Divider sx={{ mb: 1 }} />
          {!AppConfig.isInstalled() && AppConfig.getDevicePlatform() != "chrome" && (
            <ListItem key='help' disablePadding>
            {/* <ListItemButton sx={{ textAlign: 'center'}} onClick={() => handleNavClick("instalar")}>
              <ListItemText primary='Instalar App' sx={{ textAlign: 'center' }} />
              <InstallMobileIcon />
            </ListItemButton> */}
            <InstallPrompt/>
          </ListItem>
          )}
          <ListItem key='logout' disablePadding>
            <ListItemButton sx={{ textAlign: 'center'}} onClick={handleLogout}>
              <ListItemText primary='Cerrar Sesion' sx={{ textAlign: 'center' }} />
              <LogoutIcon />
            </ListItemButton>
          </ListItem>
          </>) :
          (<ListItem key='login' disablePadding>
            <ListItemButton sx={{ textAlign: 'center'}} onClick={() => handleNavClick('login')}>
              <ListItemText primary='Iniciar Sesion' sx={{ textAlign: 'center' }} />
              <LoginIcon />
            </ListItemButton>
          </ListItem>)
        }
      </List>
    </Box>
    </>
  );

  const container = window !== undefined ? () => window().document.body : undefined;
  return (
    <Box sx={{ flexGrow: 1 }}>
      {redirect.trigger && (
        <Navigate to={redirect.route} replace={true} />
      )}
      <AppBar position="fixed">
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerToggle}
            sx={{ mr: 0.5 }}
          >
            <MenuIcon />
          </IconButton>
          {history.length > 2 && navbarState.showBack && (
            <Button
              color="inherit"
              startIcon={<ChevronLeftIcon />}
              onClick={() => handleBackClick()}
              sx={{
                mr: 1,
                textTransform: 'none',
                borderRadius: '20px',
                padding: '4px 12px',
                backgroundColor: 'rgba(255, 255, 255, 0.1)',
                '&:hover': {
                  backgroundColor: 'rgba(255, 255, 255, 0.2)',
                },
              }}
            >
              Atrás
            </Button>
          )}
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{
              flexGrow: 1,
              display: { sm: 'block' },
              fontFamily: 'Lexend, sans-serif',
              fontWeight: 'bold',
            }}
          >
            {genHeader()}
          </Typography>

          {navbarState.showOptions && (
            <IconButton
              size="large"
              edge="end"
              color="inherit"
              aria-label="open options"
              onClick={handleDrawerToggle}
              sx={{ ml: 2 }}
            >
              <MoreVertIcon />
            </IconButton>
          )}
        </Toolbar>
      </AppBar>
      <Offset />
      <Box component="nav">
        <SwipeableDrawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          onOpen={handleDrawerToggle}
          SwipeAreaProps={{
            swipeAreaWidth:"100"
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </SwipeableDrawer>
      </Box>
      <Snackbar
          open={snackbar.open}
          autoHideDuration={snackbar.duration}
          onClose={() => setSnackbar({...snackbar, open: false})}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          sx={{
            mt: "3.5rem",
            boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)",
            borderRadius: "1rem",
            border: "0.6px solid #4871e6",
            backgroundColor: "#b7cbf9",
          }}
      >
        <Box sx={{ p: 3, color: "#021545", backgroundColor: "#4871e6", borderRadius: "1rem" }}>
        <IconButton
          size="large"
          edge="end"
          color="inherit"
          aria-label="open drawer"
          onClick={() => setSnackbar({...snackbar, open: false})}
          sx={{
            position: 'absolute',
            top: 0,
            right: 6,
            color: 'white' // Add this line to make the IconButton white
          }}
        >
          <CloseIcon />
        </IconButton>
          <Typography variant='body2' sx={{ mt: 1, color: "white", fontSize: "95%" }}>
            {snackbar.message}
          </Typography>
        </Box>
      </Snackbar>
    </Box>
  );
}