import * as React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import axios from 'axios';

import AppConfig from '../services/app-config';
import BackgroundImage from '../components/Cityline';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import { Navigate } from 'react-router-dom';


import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import DownloadIcon from '@mui/icons-material/Download';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import 'dayjs/locale/es';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { CardActions } from '@mui/material';

import DriveEtaIcon from '@mui/icons-material/DriveEta';
import DirectionsWalkIcon from '@mui/icons-material/DirectionsWalk';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';

export default function App({setNavbarState, navbarState}) {
  const [redirect, setRedirect] = React.useState({ trigger: false, route: '/' })
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [date,setDate] = React.useState(dayjs());
  const [carVisits, setCarVisits] = React.useState(0);
  const [walkingVisits, setWalkingVisits] = React.useState(0);
  const [events, setEvents] = React.useState([]);
  const [searchValue, setSearchValue] = React.useState("");
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const apiUrl = process.env.REACT_APP_MGO_API_URL;
  const [showBackdrop, setShowBackdrop] = React.useState(false);
  const [showIdBackdrop, setShowIdBackdrop] = React.useState(false);
  const [imageLoaded, setImageLoaded] = React.useState(false);
  const [idDialog,setIdDialog] = React.useState({show: false, id: null, title: ""});

  const userCors = process.env.REACT_APP_USE_CORS;
  if ( userCors === "true") {
    axios.defaults.withCredentials = true
  }

  const filteredEvents = events.filter(event =>
    event.event.toLowerCase().includes(searchValue.toLowerCase())
    || event.by_name.toLowerCase().includes(searchValue.toLowerCase())
    || event.by.toLowerCase().includes(searchValue.toLowerCase())
    || event.code.created_by.toLowerCase().includes(searchValue.toLowerCase())
    || event.code.created_by_name.toLowerCase().includes(searchValue.toLowerCase())
    || event.code.unit.toLowerCase().includes(searchValue.toLowerCase())
    || event.code.visitor_name.toLowerCase().includes(searchValue.toLowerCase())
    || event.code.vehicle_plate.toLowerCase().includes(searchValue.toLowerCase())
  );

  function Stats({ carVisits, walkingVisits }) {  
      return (
          <Box sx={{ mt: 1 }}>
              {/* <Card sx={{ backgroundColor: 'transparent' }}>
                  <CardContent> */}
                      <Typography variant="h6">
                          Estadisticas
                      </Typography>
                      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                          <Typography variant="h4" component="p"  sx={{ml: 4}}>
                              <DriveEtaIcon /> {carVisits}
                          </Typography>
                          <Typography variant="h4" component="p" sx={{mr: 4}}>
                              {walkingVisits} <DirectionsWalkIcon />
                          </Typography>
                      </Box>
                  {/* </CardContent>
              </Card> */}
          </Box>
      )
  }
  const closeIdDialog = () => {
    setIdDialog({show: false, id: null, title: ""});
    setImageLoaded(false);
  }

  const openDialog = (id,visitor_name) => {
    setShowIdBackdrop(true);
    setIdDialog({show: true, id: id, title: visitor_name});
  }

  function downloadImage(imageId) {
    const apiUrl = process.env.REACT_APP_MGO_API_URL;
    const downloadUrl = `${apiUrl}/img/id/${imageId}.jpg`;
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.download = `image_${imageId}.jpg`;
    link.click();
  }

  function EventList({ events }) {
    return (
      <Box sx={{ mt: 1 }}>
        {filteredEvents.map((event, index) => (
          <Card key={index} sx={{ mb: 1 }}>
            <CardContent>
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Typography variant="h5" component="h2">
                  {(() => {
                    switch (event.event) {
                      case "ingreso":
                        if (event.code.vehicle) {
                          return <DriveEtaIcon/>;
                        } else {
                          return <DirectionsWalkIcon />;
                        }
                      case "acceso creado":
                        return <CheckBoxIcon/>;
                      default:
                        return null;
                    }
                    })()} {AppConfig.titleCase(event.event)}
                    </Typography>
                    <Typography variant="h6" component="h2" sx={{mr: "5%"}}>
                    {AppConfig.formatHour(event.ts)}
                    </Typography>
                    
                  </Box>
                  <Box sx={{ml: 4}}>
                  <Typography variant="subtitle1" component="p" sx={{mt: -0.2}} style={{fontSize: 14}}>
                  {event.code.unit}
                  </Typography>
                  <Typography variant="subtitle1" component="p" >
                  <b>{event.code.visitor_name}</b>
                  </Typography>
                  <Typography variant="subtitle1" component="p" sx={{mt: -0.5}}>
                    {event.by_name && `${AppConfig.titleCase(event.event)} ${event.event === "ingreso" ? "dado" : ""} por ${event.by_name}`}
                  </Typography>
                  <Typography variant="subtitle2" component="p">
                    {event.code.vehicle_plate && `Placa: ${event.code.vehicle_plate}`}
                  </Typography>
                  </Box>
                  
                </CardContent>
                <CardActions sx={{ justifyContent: 'flex-end' }}>
                  {event.image_id && (
                  <Button size="large" variant='outlined' color="secondary" onClick={() => openDialog(event.image_id,event.code.visitor_name)} sx={{ mr: 2, mb: 1, mt: -2.5}}>
                    Ver ID
                  </Button>
                  
                  )}
                </CardActions>
                </Card>
              ))}
              </Box>
            );
  }

  

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
  };

  const handleDateChange = (newDate) => {
    setDate(newDate);
  }

  React.useEffect(() => {
    setNavbarState({...navbarState, showBack: true, showOptions: false, showSearch: false})
    const fetchEvents = async () => {
      try {
        setShowBackdrop(true);
        const response = await axios.get(`${apiUrl}/logs/${date.format('DDMMYY')}`);
        let vehicleTrueCount = 0;
        let vehicleFalseCount = 0;
        AppConfig.parseResponse(response);
        response.data.data.forEach(event => {
          if (event.event  === "ingreso"){
            if (event.code.vehicle) {
              vehicleTrueCount++;
            } else {
              vehicleFalseCount++;
            }
            }
          if (event.code.vehicle_plate === null) {
            event.code.vehicle_plate = "";
          }
        });
        setCarVisits(vehicleTrueCount);
        setWalkingVisits(vehicleFalseCount);
        setEvents(response.data.data);
      } catch (error) {
        setSnackbarMessage('Error fetching events');
        setSnackbarOpen(true);
      } finally {
        setShowBackdrop(false);
      }
    };

    fetchEvents();
  }, [date]);

  const handleImageLoad = () => {
    setShowIdBackdrop(false);
    setImageLoaded(true);
  };

  return (
    <>
    <Container maxWidth="sm">
      {redirect.trigger && (
        <Navigate to={redirect.route} replace={true} />
      )}
      <Snackbar open={snackbarOpen} autoHideDuration={3000} onClose={handleClose} anchorOrigin={{ vertical: "top", horizontal: "center" }} sx={{mt: "3.2rem"}}>
        <Alert onClose={handleClose} severity="warning" sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
      <Backdrop open={showBackdrop} sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, color: '#fff' }}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box >
      <TextField 
          fullWidth 
          size="medium" 
          id='search' 
          sx={{ 
            textAlign: 'center', 
            mb: 0, 
            mt: 2,
          }} 
          label="Busqueda" 
          variant="outlined" 
          onChange={handleSearchChange}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton>
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            ),
            style: { borderRadius: '8px'}
          }}
        />
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"es"}>
            <DatePicker 
              sx={{ mt: 2, width: '100%' }}
              defaultValue={dayjs()}
              label="Actividad del Dia"
              onChange={handleDateChange}
              maxDate={dayjs()}
              minDate={dayjs(AppConfig.get("starting_date"))}
            />
        </LocalizationProvider>
        <Stats carVisits={carVisits} walkingVisits={walkingVisits}/>
        <Divider sx={{ mt: 2, mb: 2}}/>
        <EventList events={events} />
      </Box>
        
    <BackgroundImage />
    </Container>
    <Dialog open={idDialog.show} onClose={closeIdDialog} fullWidth maxWidth="md">
      <DialogTitle>{idDialog.title}</DialogTitle>
      <DialogContent>
        <Backdrop open={showIdBackdrop} sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, color: '#fff', mt: 6 }}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <img
          src={`${apiUrl}/img/id/${idDialog.id}.jpg`}
          alt="Imagen de ID"
          style={{ width: '100%', border: '15px', paddingLeft: 0, borderRadius: '10px' }}
          onLoad={handleImageLoad}
          hidden={!imageLoaded}
        />
      </DialogContent>
      <DialogActions sx={{ mt: -1, mb: 1}}>
      {imageLoaded && (<>
        <Button varitan="text" color="primary" onClick={() => downloadImage(idDialog.id)}>
          <DownloadIcon sx={{mr: 0.5}}/>
          Descargar
        </Button>
        <Button onClick={closeIdDialog} variant="outlined" color="primary">
          Cerrar
        </Button>
        </>)}
      </DialogActions>
    </Dialog>
    </>
  );
}