import * as React from 'react';
import { Navigate } from 'react-router-dom';
import axios from 'axios';
import AppConfig from '../services/app-config';
import AuthService from '../services/auth.js';

import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Switch from '@mui/material/Switch';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import Input from '@mui/material/Input';
import FormGroup from '@mui/material/FormGroup';
import Divider from '@mui/material/Divider';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import InputAdornment from '@mui/material/InputAdornment';
import EventIcon from '@mui/icons-material/Event';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SaveIcon from '@mui/icons-material/Save';
import LockIcon from '@mui/icons-material/Lock';
import EditIcon from '@mui/icons-material/Edit';
import FormHelperText from '@mui/material/FormHelperText';

import Resizer from 'react-image-file-resizer';

export default function AmenityAdmin({ setNavbarState, navbarState }) {
  const [redirect, setRedirect] = React.useState({ trigger: false, route: '/' });
  const [loading, setLoading] = React.useState(true);
  const [amenities, setAmenities] = React.useState([]); // Initialize as an empty array
  const [selectedAmenity, setSelectedAmenity] = React.useState(null);
  const [snackbar, setSnackbar] = React.useState({ open: false, message: '', severity: 'success' });
  const [showBackdrop, setShowBackdrop] = React.useState(false);
  const [blockModalOpen, setBlockModalOpen] = React.useState(false);
  const [newAmenityMode, setNewAmenityMode] = React.useState(false);
  const [newImageNeeded, setNewImageNeeded] = React.useState(false);
  const [blockData, setBlockData] = React.useState({
    motivo: "",
    fecha_inicio: new Date(new Date().getTime() + (60 - new Date().getMinutes()) * 60000).toISOString().slice(0, 16),
    fecha_fin: new Date(new Date().getTime() + (2 - (new Date().getMinutes() % 120)) * 60000).toISOString().slice(0, 16)
  });
  const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);

  const amenityId = new URLSearchParams(location.search).get('id');
  const newAmenity =  {
    "id": "new",
    "nombre": "",
    "descripcion": "",
    "habilitada": true,
    "reservacion": {
      "necesaria": false,
      "tiempo_maximo": 240,
      "tiempo_minimo": 30,
      "max_dias_previos": 45,
      "req_aprobacion": false,
      "monto_pago": 0,
      "max_al_dia": 1
    },
    "cantidad": 1,
    "bloquear": {
      "motivo": "",
      "fecha_inicio": 10000,
      "fecha_fin": 10000
    },
    "servicio": [],
    "horarios": [
      {
        "dias": [
          1,
          2,
          3,
          4,
          5,
          6,
          7
        ],
        "hora_inicio": "08:00",
        "hora_fin": "21:00"
      }
    ],
    "obs": [
      "El propietario es responsable por el comportamiento de niños e invitados adentro del area",
      "Niños menores de 12 años deben de estar acompañados por un adulto"
    ]
  }

  

  const apiUrl = process.env.REACT_APP_MGO_API_URL;

  React.useEffect(() => {
    setNavbarState({ ...navbarState, showBack: true, showOptions: false, showSearch: false });
    fetchAmenities();
  }, []);

  const fetchAmenities = () => {
    setShowBackdrop(true);
    axios.get(`${apiUrl}/amenities/list`)
      .then(response => {
        AppConfig.parseResponse(response);
        if (response.status === 200) {
          // Convert the object to an array
          const amenitiesArray = Object.entries(response.data.data).map(([id, value]) => ({ id, ...value }));
          setAmenities(amenitiesArray);
          if (amenityId !== null) {
            setSelectedAmenity({...response.data.data[amenityId],id: amenityId})
          }
        }
        setShowBackdrop(false);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching amenities:', error);
        setSnackbar({ open: true, message: 'Error fetching amenities', severity: 'error' });
        setShowBackdrop(false);
        setLoading(false);
      });
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file.size > 1024 * 1024) {
      Resizer.imageFileResizer(
        file,
        800, // Max width
        800, // Max height
        'JPEG', // Output format
        80, // Quality
        0, // Rotation
        (compressedFile) => {
          uploadImage(compressedFile);
        },
        'blob' // Output type
      );
    } else {
      uploadImage(file);
    }
  };

  const uploadImage = (file) => {
    setShowBackdrop(true);
    const formData = new FormData();
    formData.append('image', file);
    formData.append('path', `amenities/${selectedAmenity.id}.jpg`);

    axios.post(`${apiUrl}/media/upload`, formData)
      .then((response) => {
        AppConfig.parseResponse(response);
        if (response.status === 200) {
          setSnackbar({ open: true, message: 'Imagen actualizada correctamente', severity: 'success' });
          // Force a re-render of the image by updating the state
          setSelectedAmenity({...selectedAmenity});
        }
        setShowBackdrop(false);
        setNewImageNeeded(false);
      })
      .catch((error) => {
        console.error('Error uploading image:', error);
        setSnackbar({ open: true, message: 'Error al subir la imagen', severity: 'error' });
        setShowBackdrop(false);
      });
  };

  const handleAmenitySelect = (amenity) => {
    setNewAmenityMode(false);
    setNewImageNeeded(false);
    setSelectedAmenity(amenity);
  };

  const handleAmenityBlock = (amenity) => {
    setBlockData({
      motivo: "",
      fecha_inicio: null,
      fecha_fin: null,
      id: amenity.id,
      amenity: amenity.nombre
    });
    setBlockModalOpen(true);
  };

  const handleBlockInputChange = (event) => {
    const { name, value } = event.target;
    setBlockData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleBlockSubmit = () => {
    setShowBackdrop(true);
    let ts = Math.floor(new Date().getTime() / 1000);
    if (new Date(blockData.fecha_fin).getTime() / 1000 < ts) {
      setSnackbar({ open: true, message: 'No es posible bloquear una fecha pasada', severity: 'error' });
      setShowBackdrop(false);
      return;
    }
    if (blockData.fecha_inicio > blockData.fecha_fin) {
      setSnackbar({ open: true, message: 'La fecha de inicio no puede ser mayor a la fecha de fin', severity: 'error' });
      setShowBackdrop(false);
      return;
    }
    axios.post(`${apiUrl}/amenity/${blockData.id}/block`, {
        motivo: blockData.motivo,
        fecha_inicio: blockData.fecha_inicio ? new Date(blockData.fecha_inicio).getTime() / 1000 : null,
        fecha_fin: blockData.fecha_fin ? new Date(blockData.fecha_fin).getTime() / 1000 : null
    })
      .then(response => {
        AppConfig.parseResponse(response);
        if (response.status === 200) {
          setSnackbar({ open: true, message: 'Amenidad bloqueada correctamente', severity: 'success' });
          fetchAmenities();
          setBlockModalOpen(false);
        }
      })
      .catch(error => {
        console.error('Error blocking amenity:', error);
        setSnackbar({ open: true, message: 'Error al bloquear la amenidad', severity: 'error' });
      })
      .finally(() => {
        setShowBackdrop(false);
      });
  };

  const handleInputChange = (event) => {
    const { name, value, checked, type } = event.target;
    setSelectedAmenity(prev => ({
      ...prev,
      [name]: type === 'checkbox' || type === 'switch' ? checked : value
    }));
  };

  const handleNestedInputChange = (event, parentKey) => {
    const { name, value, checked, type } = event.target;
    setSelectedAmenity(prev => ({
      ...prev,
      [parentKey]: {
        ...prev[parentKey],
        [name]: type === 'checkbox' || type === 'switch' ? checked : value
      }
    }));
  };

  const handleArrayInputChange = (index, value, arrayName) => {
    setSelectedAmenity(prev => ({
      ...prev,
      [arrayName]: prev[arrayName].map((item, i) => i === index ? value : item)
    }));
  };

  const handleAddArrayItem = (arrayName, defaultValue) => {
    setSelectedAmenity(prev => ({
      ...prev,
      [arrayName]: [...prev[arrayName], defaultValue]
    }));
  };

  const handleRemoveArrayItem = (index, arrayName) => {
    setSelectedAmenity(prev => ({
      ...prev,
      [arrayName]: prev[arrayName].filter((_, i) => i !== index)
    }));
  };

  const handleServiceChange = (index, field, value) => {
    const newServicio = [...selectedAmenity.servicio];
    newServicio[index] = { ...newServicio[index], [field]: value };
    setSelectedAmenity({ ...selectedAmenity, servicio: newServicio });
  };

  const addServiceBlock = () => {
    const newServicio = [...selectedAmenity.servicio, { dias: [], franjas: ["00:00-00:00"] }];
    setSelectedAmenity({ ...selectedAmenity, servicio: newServicio });
  };

  const handleSave = () => {
    setShowBackdrop(true);
    if (newAmenityMode && newImageNeeded) {
      setSnackbar({ open: true, message: 'Debes agregar una imagen', severity: 'error' });
      setShowBackdrop(false);
      return;
    }
    if (selectedAmenity.nombre === "") {
      setSnackbar({ open: true, message: 'Debes agregar un nombre', severity: 'error' });
      setShowBackdrop(false);
      return;
    }
    if (selectedAmenity.descripcion === "") {
      setSnackbar({ open: true, message: 'Debes agregar una descripción', severity: 'error' });
      setShowBackdrop(false);
      return;
    }
    const hasEmptyDias = selectedAmenity.horarios.some(horario => horario.dias.length === 0);
    if (hasEmptyDias) {
      setSnackbar({ open: true, message: 'Todos los horarios deben tener al menos un día seleccionado.', severity: 'error' });
      setShowBackdrop(false);
      return;
    }
    const hasEmptyDiasServicio = selectedAmenity.servicio.some(servicio => servicio.dias.length === 0);
    if (hasEmptyDiasServicio) {
      setSnackbar({ open: true, message: 'Todos los servicios deben tener al menos un día seleccionado.', severity: 'error' });
      setShowBackdrop(false);
      return;
    }
    axios.post(`${apiUrl}/amenity/update`, selectedAmenity)
      .then(response => {
        AppConfig.parseResponse(response);
        if (response.status === 200) {
          setSnackbar({ open: true, message: 'Amenidad actualizada correctamente', severity: 'success' });
          fetchAmenities();
        }
        setShowBackdrop(false);
        setRedirect({ trigger: true, route: `/amenidad?id=${selectedAmenity.id}` });
      })
      .catch(error => {
        console.error('Error actualizando la amenidad:', error);
        setSnackbar({ open: true, message: 'Error actualizando la amenidad', severity: 'error' });
        setShowBackdrop(false);
      });
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  const handleAddNewAmenity = () => {
    // TODO: Implement add new amenity functionality
    setNewAmenityMode(true);
    setNewImageNeeded(true);
    setSelectedAmenity(newAmenity);
    console.log("Add new amenity clicked");
  };

  const handleViewAllReservations = () => {
    setRedirect({ trigger: true, route: '/reservaciones' });
  };

  const handleDeleteAmenity = () => {
    setDeleteDialogOpen(true);
  };

  const confirmDeleteAmenity = () => {
    setShowBackdrop(true);
    axios.post(`${apiUrl}/amenity/${selectedAmenity.id}/remove`)
      .then(response => {
        AppConfig.parseResponse(response);
        if (response.status === 200) {
          setSnackbar({ open: true, message: 'Amenidad eliminada correctamente', severity: 'success' });
          fetchAmenities();
          setSelectedAmenity(null);
        }
      })
      .catch(error => {
        console.error('Error eliminando la amenidad:', error);
        setSnackbar({ open: true, message: 'Error eliminando la amenidad', severity: 'error' });
      })
      .finally(() => {
        setShowBackdrop(false);
        setDeleteDialogOpen(false);
      });
  };

  const getDaysPool = (currentIndex, array) => {
    const allDays = [1, 2, 3, 4, 5, 6, 7];
    const usedDays = array.flatMap((item, index) => 
      index !== currentIndex ? item.dias : []
    );
    return allDays.filter(day => !usedDays.includes(day));
  };

  if (!AuthService.userRoleIncludes(["admin"])) {
    return <Navigate to="/" replace={true} />;
  }

  return (
    <Container maxWidth="sm">
      {redirect.trigger && <Navigate to={redirect.route} replace={true} />}
      <Box sx={{ mt: 2 }}>
        <Snackbar open={snackbar.open} autoHideDuration={6000} anchorOrigin={{ vertical: "top", horizontal: "center" }} sx={{mt: "3.2rem"}} onClose={handleSnackbarClose}>
          <Alert onClose={handleSnackbarClose} severity={snackbar.severity}>
            {snackbar.message}
          </Alert>
        </Snackbar>
        <Backdrop open={showBackdrop} sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
          <CircularProgress color="inherit" />
        </Backdrop>
        {!loading && (
          <>
            {!selectedAmenity && (
              <>
              <Grid container spacing={2} sx={{ mb: 2 }}>
                <Grid item xs={6}>
                  <Card sx={{ overflow: 'hidden', position: 'relative' }} onClick={handleAddNewAmenity}>
                    <CardContent sx={{ position: 'relative', zIndex: 1 }}>
                      <Typography variant="h6" component="div" sx={{ fontWeight: 'bold', mb: 1 }}>
                        Agregar nueva amenidad
                      </Typography>
                      {/* <Typography variant="body2" color="text.secondary">
                        Crea una nueva amenidad
                      </Typography> */}
                    </CardContent>
                    <AddIcon sx={{
                      position: 'absolute',
                      right: -20,
                      bottom: -20,
                      fontSize: 105,
                      color: 'rgba(0, 0, 0, 0.10)',
                    }} />
                  </Card>
                </Grid>
                <Grid item xs={6}>
                  <Card sx={{ overflow: 'hidden', position: 'relative' }} onClick={handleViewAllReservations}>
                    <CardContent sx={{ position: 'relative', zIndex: 1 }}>
                      <Typography variant="h6" component="div" sx={{ fontWeight: 'bold', mb: 1 }}>
                        Ver todas las reservaciones
                      </Typography>
                      {/* <Typography variant="body2" color="text.secondary">
                        Gestiona las reservaciones
                      </Typography> */}
                    </CardContent>
                    <EventIcon sx={{
                      position: 'absolute',
                      right: -20,
                      bottom: -20,
                      fontSize: 105,
                      color: 'rgba(0, 0, 0, 0.10)',
                    }} />
                  </Card>
                </Grid>
              </Grid>
              <Divider sx={{ mb: 2 }} />
              </>
            )}
            {!selectedAmenity ? (
              amenities.map((amenity) => (
                <Card key={amenity.id} sx={{ mb: 2, position: 'relative', overflow: 'hidden' }}>
                  <CardContent sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'flex-start', zIndex: 1 }}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>{amenity.nombre}</Typography>
                    <Typography variant="body1">{amenity.descripcion}</Typography>
                  </CardContent>
                  
                  <Box sx={{ position: 'absolute', top: 0, right: 0, width: '100%', height: '100%', overflow: 'hidden' }}>
                    <img 
                      src={`${apiUrl}/img/amenities/${amenity.id}.jpg`} 
                      alt={amenity.nombre} 
                      style={{ 
                        position: 'absolute', 
                        right: 0,
                        bottom: 0,
                        width: '100%', 
                        height: '100%', 
                        objectFit: 'cover',
                        filter: 'grayscale(100%)',
                        opacity: 0.08,
                      }} 
                    />
                  </Box>
                  <CardActions sx={{ justifyContent: 'flex-end', zIndex: 1 }}>
                  <Button size="small" startIcon={<LockIcon />} variant="outlined" color="primary" onClick={() => handleAmenityBlock(amenity)}>Bloquear</Button>
                  <Button size="small" startIcon={<EditIcon />} variant="outlined" color="secondary" onClick={() => handleAmenitySelect(amenity)}>Editar</Button>
                  </CardActions>
                </Card>
              ))
            ) : (
              <Card>
                <Typography variant="h5" sx={{ textAlign: 'center', fontWeight: 'bold' }}>{selectedAmenity.nombre}</Typography>
                <Box sx={{ position: 'relative' }}>
                  <img 
                    src={newImageNeeded ? '/amenityplaceholder.png' : `${apiUrl}/img/amenities/${selectedAmenity.id}.jpg`}  
                    alt="Amenity Image" 
                    style={{ width: '100%', borderTopLeftRadius: '15px', borderTopRightRadius: '15px', paddingLeft: 0 }} 
                  />
                  <Box sx={{ position: 'relative', display: 'flex', justifyContent: 'flex-end', padding: '8px' }}>
                    <label htmlFor="contained-button-file">
                      <Input
                        accept="image/*"
                        id="contained-button-file"
                        type="file"
                        onChange={handleImageChange}
                        style={{ display: 'none' }}
                      />
                      <Button variant="outlined" component="span" color="primary">
                        {newAmenityMode ? 'Agregar Imagen' : 'Cambiar Imagen'}
                      </Button>
                    </label>
                  </Box>
                </Box>
                <CardContent>
                  <TextField
                    fullWidth
                    label="Nombre"
                    name="nombre"
                    value={selectedAmenity.nombre}
                    onChange={handleInputChange}
                    margin="normal"
                  />
                  <TextField
                    fullWidth
                    label="Descripción"
                    name="descripcion"
                    value={selectedAmenity.descripcion}
                    onChange={handleInputChange}
                    margin="normal"
                    multiline
                    rows={3}
                  />
                  <FormControlLabel
                    control={
                      <Switch
                        checked={selectedAmenity.habilitada}
                        onChange={handleInputChange}
                        name="habilitada"
                      />
                    }
                    label="Habilitada"
                    // Removed helperText from FormControlLabel
                  />
                  <FormHelperText>Si la amenidad no está habilitada, no se mostrará a los usuarios</FormHelperText>
                  <Divider sx={{ my: 2 }} />
                  <Typography variant="h6" sx={{ mt: 2 }}>Horarios</Typography>
                  <Typography variant="body2" color="text.secondary" sx={{ mt: 1, mb: 2 }}>
                    Agregue bloques de horarios para la amenidad. Cada bloque puede tener diferentes días y horas.
                  </Typography>
                  {selectedAmenity.horarios.map((horario, index) => {
                    const availableDays = getDaysPool(index, selectedAmenity.horarios);
                    return (
                      <Box key={index} sx={{ mb: 2, p: 2, border: '1px solid #e0e0e0', borderRadius: '4px' }}>
                        <FormGroup row>
                          {['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado', 'Domingo'].map((day, dayIndex) => (
                            <FormControlLabel
                              key={dayIndex}
                              control={
                                <Checkbox
                                  checked={horario.dias.includes(dayIndex + 1)}
                                  onChange={(e) => {
                                    const newDias = e.target.checked
                                      ? [...horario.dias, dayIndex + 1]
                                      : horario.dias.filter(d => d !== dayIndex + 1);
                                    const newHorarios = [...selectedAmenity.horarios];
                                    newHorarios[index] = { ...horario, dias: newDias };
                                    setSelectedAmenity({ ...selectedAmenity, horarios: newHorarios });
                                  }}
                                  disabled={!availableDays.includes(dayIndex + 1) && !horario.dias.includes(dayIndex + 1)}
                                />
                              }
                              label={day}
                            />
                          ))}
                        </FormGroup>
                        <Box sx={{ display: 'flex', gap: 2, mt: 2 }}>
                          <TextField
                            label="Hora de inicio"
                            type="time"
                            value={horario.hora_inicio}
                            onChange={(e) => {
                              const newHorarios = [...selectedAmenity.horarios];
                              newHorarios[index] = { ...horario, hora_inicio: e.target.value };
                              setSelectedAmenity({ ...selectedAmenity, horarios: newHorarios });
                            }}
                            InputLabelProps={{ shrink: true }}
                          />
                          <TextField
                            label="Hora de fin"
                            type="time"
                            value={horario.hora_fin}
                            onChange={(e) => {
                              const newHorarios = [...selectedAmenity.horarios];
                              newHorarios[index] = { ...horario, hora_fin: e.target.value };
                              setSelectedAmenity({ ...selectedAmenity, horarios: newHorarios });
                            }}
                            InputLabelProps={{ shrink: true }}
                          />
                        </Box>
                        <Button 
                          variant="outlined" 
                          color="error" 
                          startIcon={<DeleteIcon />}
                          sx={{ mt: 2 }}
                          onClick={() => {
                            const newHorarios = selectedAmenity.horarios.filter((_, i) => i !== index);
                            setSelectedAmenity({ ...selectedAmenity, horarios: newHorarios });
                          }}
                        >
                          Eliminar Horario
                        </Button>
                      </Box>
                    );
                  })}
                  <Button 
                    variant="outlined" 
                    color="primary" 
                    startIcon={<AddIcon />}
                    sx={{ mt: 2 }}
                    onClick={() => {
                      const configuredDays = selectedAmenity.horarios.flatMap(h => h.dias);
                      const availableDays = [1, 2, 3, 4, 5, 6, 7].filter(day => !configuredDays.includes(day));
                      const newHorario = { dias: availableDays, hora_inicio: "00:00", hora_fin: "23:59" };
                      setSelectedAmenity({ 
                        ...selectedAmenity, 
                        horarios: [...selectedAmenity.horarios, newHorario] 
                      });
                    }}
                  >
                    Agregar Nuevo Horario
                  </Button>

                  <Divider sx={{ my: 2 }} />
                  <Typography variant="h6" sx={{ mt: 2 }}>Franjas de Mantenimiento</Typography>
                  <Typography variant="body2" color="text.secondary" sx={{ mt: 1, mb: 2 }}>
                    Franjas de horario durante las cuales la amenidad no estará disponible para los usuarios de manera recurrente para mantenimiento, limpieza, etc.
                  </Typography>
                  {selectedAmenity.servicio.map((servicio, index) => {
                    const availableDays = getDaysPool(index, selectedAmenity.servicio);
                    return (
                      <Box key={index} sx={{ mb: 2, p: 2, border: '1px solid #e0e0e0', borderRadius: '4px' }}>
                        <FormGroup row>
                          {['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado', 'Domingo'].map((day, dayIndex) => (
                            <FormControlLabel
                              key={dayIndex}
                              control={
                                <Checkbox
                                  checked={servicio.dias.includes(dayIndex + 1)}
                                  onChange={(e) => {
                                    const newDias = e.target.checked
                                      ? [...servicio.dias, dayIndex + 1]
                                      : servicio.dias.filter(d => d !== dayIndex + 1);
                                    handleServiceChange(index, 'dias', newDias);
                                  }}
                                  disabled={!availableDays.includes(dayIndex + 1) && !servicio.dias.includes(dayIndex + 1)}
                                />
                              }
                              label={day}
                            />
                          ))}
                        </FormGroup>
                        {servicio.franjas.map((franja, franjaIndex) => {
                          const [inicio, fin] = franja.split('-');
                          return (
                            <Box key={franjaIndex} sx={{ display: 'flex', flexDirection: 'column', mb: 2 }}>
                              <Typography variant="subtitle2" sx={{ mb: 1 }}>Franja {franjaIndex + 1}</Typography>
                              <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                                <TextField
                                  label="Hora de inicio"
                                  type="time"
                                  value={inicio}
                                  onChange={(e) => {
                                    const newFranjas = [...servicio.franjas];
                                    newFranjas[franjaIndex] = `${e.target.value}-${fin}`;
                                    handleServiceChange(index, 'franjas', newFranjas);
                                  }}
                                  InputLabelProps={{ shrink: true }}
                                />
                                <TextField
                                  label="Hora de fin"
                                  type="time"
                                  value={fin}
                                  onChange={(e) => {
                                    const newFranjas = [...servicio.franjas];
                                    newFranjas[franjaIndex] = `${inicio}-${e.target.value}`;
                                    handleServiceChange(index, 'franjas', newFranjas);
                                  }}
                                  InputLabelProps={{ shrink: true }}
                                />
                                <IconButton onClick={() => {
                                  const newFranjas = servicio.franjas.filter((_, i) => i !== franjaIndex);
                                  handleServiceChange(index, 'franjas', newFranjas);
                                }}>
                                  <DeleteIcon />
                                </IconButton>
                              </Box>
                            </Box>
                          );
                        })}
                        <Button 
                          variant="outlined" 
                          startIcon={<AddIcon />}
                          sx={{ mt: 2 }}
                          onClick={() => {
                            const newFranjas = [...servicio.franjas, "00:00-00:00"];
                            handleServiceChange(index, 'franjas', newFranjas);
                          }}
                        >
                          Agregar Franja
                        </Button>
                        <Button 
                          variant="outlined" 
                          color="error" 
                          startIcon={<DeleteIcon />}
                          sx={{ mt: 2, ml: 2 }}
                          onClick={() => {
                            const newServicio = selectedAmenity.servicio.filter((_, i) => i !== index);
                            setSelectedAmenity({ ...selectedAmenity, servicio: newServicio });
                          }}
                        >
                          Eliminar Bloque de Servicio
                        </Button>
                      </Box>
                    );
                  })}
                  <Button 
                    variant="outlined" 
                    color="primary" 
                    startIcon={<AddIcon />}
                    sx={{ mt: 2 }}
                    onClick={addServiceBlock}
                  >
                    Agregar Bloque de Franjas de Servicio
                  </Button>

                  <Divider sx={{ my: 2 }} />
                  <Typography variant="h6" sx={{ mt: 2 }}>Reservación</Typography>
                  <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                    Si la amenidad requiere reservación, active la casilla y complete los siguientes campos.
                  </Typography>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={selectedAmenity.reservacion.necesaria}
                        onChange={(e) => handleNestedInputChange(e, 'reservacion')}
                        name="necesaria"
                      />
                    }
                    label="Reservación requerida"
                  />
                  <TextField
                    fullWidth
                    label="Tiempo máximo (minutos)"
                    name="tiempo_maximo"
                    type="number"
                    value={selectedAmenity.reservacion.tiempo_maximo}
                    onChange={(e) => {
                      const value = parseInt(e.target.value, 10) || 0;
                      handleNestedInputChange({ target: { name: 'tiempo_maximo', value } }, 'reservacion');
                    }}
                    margin="normal"
                    disabled={!selectedAmenity.reservacion.necesaria}
                    helperText="Tiempo máximo de duración de la reserva en minutos"
                  />
                  <TextField
                    fullWidth
                    label="Tiempo mínimo (minutos)"
                    name="tiempo_minimo"
                    type="number"
                    value={selectedAmenity.reservacion.tiempo_minimo}
                    onChange={(e) => {
                      const value = parseInt(e.target.value, 10) || 0;
                      handleNestedInputChange({ target: { name: 'tiempo_minimo', value } }, 'reservacion');
                    }}
                    margin="normal"
                    disabled={!selectedAmenity.reservacion.necesaria}
                    helperText="Tiempo mínimo de duración de la reserva en minutos"
                  />
                  <TextField
                    fullWidth
                    label="Máximo días previos"
                    name="max_dias_previos"
                    type="number"
                    value={selectedAmenity.reservacion.max_dias_previos}
                    onChange={(e) => {
                      const value = parseInt(e.target.value, 10) || 0;
                      handleNestedInputChange({ target: { name: 'max_dias_previos', value } }, 'reservacion');
                    }}
                    margin="normal"
                    disabled={!selectedAmenity.reservacion.necesaria}
                    helperText="Cuantos días de antelación puede reservar un usuario"
                  />

                  <TextField
                    fullWidth
                    label="Monto de pago"
                    name="monto_pago"
                    type="number"
                    value={Number(Math.max(0, parseFloat(selectedAmenity.reservacion.monto_pago || 0)).toFixed(2))}
                    onChange={(e) => {
                      const value = Math.max(0, parseFloat(parseFloat(e.target.value).toFixed(2)));
                      handleNestedInputChange({ target: { name: 'monto_pago', value } }, 'reservacion');
                      if (value > 0) {
                        setSelectedAmenity(prev => ({
                          ...prev,
                          reservacion: {
                            ...prev.reservacion,
                            req_aprobacion: true
                          }
                        }));
                      }
                    }}
                    margin="normal"
                    disabled={!selectedAmenity.reservacion.necesaria}
                    InputProps={{
                      startAdornment: <InputAdornment position="start">Q.</InputAdornment>,
                      inputProps: { min: 0, step: 0.01 }
                    }}
                    helperText="Si la amenidad requiere pago, ingrese el monto. Si no, ingrese 0."
                  />

                  <TextField
                    fullWidth
                    label="Máximo al día"
                    name="max_al_dia"
                    type="number"
                    value={selectedAmenity.reservacion.max_al_dia}
                    onChange={(e) => {
                      const value = parseInt(e.target.value, 10) || 0;
                      handleNestedInputChange({ target: { name: 'max_al_dia', value } }, 'reservacion');
                    }}
                    margin="normal"
                    disabled={!selectedAmenity.reservacion.necesaria}
                    helperText="Cuantas veces un usuario puede reservar esta amenidad al día"
                  />

                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={selectedAmenity.reservacion.req_aprobacion}
                        onChange={(e) => handleNestedInputChange(e, 'reservacion')}
                        name="req_aprobacion"
                        disabled={!selectedAmenity.reservacion.necesaria || selectedAmenity.reservacion.monto_pago > 0}
                      />
                    }
                    label="Requiere aprobación"
                  />
                  <FormHelperText disabled={!selectedAmenity.reservacion.necesaria || selectedAmenity.reservacion.monto_pago > 0}>Si la amenidad requiere aprobación de administración para ser utilizada</FormHelperText>
                  
                  
                  {/* <TextField
                    fullWidth
                    label="Cantidad"
                    name="cantidad"
                    type="number"
                    value={selectedAmenity.cantidad}
                    onChange={handleInputChange}
                    margin="normal"
                  /> */}
                  
                  <Divider sx={{ my: 2 }} />
                  <Typography variant="h6" sx={{ mt: 2 }}>Reglas y Observaciones</Typography>
                  <Typography variant="body2" color="text.secondary" sx={{ mt: 1, mb: 2 }}>
                    Agregue reglas y observaciones para esta amenidad.
                  </Typography>
                  <List>
                    {selectedAmenity.obs.map((obs, index) => (
                      <ListItem key={index}>
                        <ListItemText
                          primary={
                            <TextField
                              fullWidth
                              value={obs}
                              onChange={(e) => handleArrayInputChange(index, e.target.value, 'obs')}
                              multiline
                            />
                          }
                        />
                        <IconButton edge="end" aria-label="delete" onClick={() => handleRemoveArrayItem(index, 'obs')}>
                          <DeleteIcon />
                        </IconButton>
                      </ListItem>
                    ))}
                  </List>
                  <Button variant="outlined" startIcon={<AddIcon />} onClick={() => handleAddArrayItem('obs', '')}>
                    Agregar observación
                  </Button>
                  
                  
                </CardContent>
                <CardActions sx={{ justifyContent: 'space-between' }}>
                  <Button size="small" startIcon={<ArrowBackIcon />} onClick={() => setSelectedAmenity(null)}>Regresar</Button>
                  <Box>
                    <Button variant="outlined" startIcon={<DeleteIcon />} color="error" size="small" onClick={handleDeleteAmenity}>Eliminar</Button>
                    <Button variant="outlined" startIcon={<SaveIcon />} size="small" onClick={handleSave} sx={{ ml: 1 }}>Guardar</Button>
                  </Box>
                </CardActions>
              </Card>
            )}
          </>
        )}
      </Box>
    <Dialog open={blockModalOpen} onClose={() => setBlockModalOpen(false)}>
      <DialogTitle>Bloquear {blockData.amenity}</DialogTitle>
      <DialogContent>
        <Typography variant="body1" color="text.secondary">Durante el periodo seleccionado, la amenidad no estará disponible para los usuarios.</Typography>
        <TextField
          fullWidth
          label="Motivo"
          name="motivo"
          value={blockData.motivo}
          onChange={handleBlockInputChange}
          placeholder="Describe brevemente el motivo por el cual no se podria utilizar la amenidad"
          margin="normal"
          multiline
        />
        <TextField
          fullWidth
          label="Fecha de inicio"
          name="fecha_inicio"
          type="datetime-local"
          value={blockData.fecha_inicio}
          onChange={handleBlockInputChange}
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{
            min: new Date().toISOString().slice(0, 16) // Set minimal date to current time
          }}
        />
        <TextField
          fullWidth
          label="Fecha de fin"
          name="fecha_fin"
          type="datetime-local"
          value={blockData.fecha_fin}
          onChange={handleBlockInputChange}
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{
            min: new Date(blockData.fecha_inicio).toISOString().slice(0, 16) // Set minimal date to current time
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setBlockModalOpen(false)}>Cancelar</Button>
        <Button onClick={handleBlockSubmit} color="secondary">Bloquear</Button>
      </DialogActions>
    </Dialog>
    <Dialog
      open={deleteDialogOpen}
      onClose={() => setDeleteDialogOpen(false)}
    >
      <DialogTitle>Confirmar eliminación</DialogTitle>
      <DialogContent>
        <DialogContentText>
          ¿Está seguro que desea eliminar la amenidad "{selectedAmenity?.nombre}"? Esta acción no se puede deshacer.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setDeleteDialogOpen(false)}>Cancelar</Button>
        <Button onClick={confirmDeleteAmenity} color="error" autoFocus>
          Eliminar
        </Button>
      </DialogActions>
    </Dialog>
    </Container>
  );
}