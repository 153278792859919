import * as React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import axios from 'axios';
import BackgroundImage from '../components/Cityline';
import { Navigate } from 'react-router-dom';

import AppConfig from '../services/app-config';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Drawer from '@mui/material/Drawer';

import eventReportService from '../services/event-report';

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://migarita.online">
        MiGarita.Online
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}
export default function Login({window,setNavbarState, navbarState}) {
  const [redirect, setRedirect] = React.useState({ trigger: false, route: '/' })
  const [email, setEmail] = React.useState("");
  const [showPwdReset,setShowPwdReset] = React.useState(false);
  const [severity, setSeverity] = React.useState("warning");
  const [password, setPassword] = React.useState("");
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const apiUrl = process.env.REACT_APP_MGO_API_URL;
  const [showBackdrop, setShowBackdrop] = React.useState(false);

  const userCors = process.env.REACT_APP_USE_CORS;
  if ( userCors === "true") {
    axios.defaults.withCredentials = true
  }

  const togglePwdReset = () => {
    setShowPwdReset(!showPwdReset);
  }

  const handleResetPassword = async () => {
    try {
      setShowBackdrop(true);
      const res = await axios.post(`${apiUrl}/request/reset/login`, { email });
      if (res.status === 200) {
        setSeverity("success");
        setSnackbarOpen(true);
        setSnackbarMessage("Si el correo es valido en nuestra base de datos, se ha enviado un correo electrónico para restablecer tu contraseña.");
        setShowPwdReset(false);
      }
    } catch (err) {
      console.log(err.message);
    } finally {
      setShowBackdrop(false);
    }
  }

  const pwdResetDrawer = (
    <Card
      sx={{ py: 3, px: 2, display: 'block', marginLeft: 'auto', marginRight: 'auto', borderTopLeftRadius: "20px", borderTopRightRadius: "20px", borderBottomRightRadius: "0px", borderBottomLeftRadius: "0px", maxWidth: "550px", width: '100%' }}>
      <CardContent>
        <Typography gutterBottom variant="h5" component="div" sx={{ mb: 3 }}>
          Restablecer Contraseña
        </Typography>
        <TextField sx={{ mb: 1}} fullWidth size="larger" value={email} id='name' label="Email" variant="outlined" onChange={(e) => setEmail(e.target.value)} />    
      </CardContent>
      <CardActions>
        <Button size="medium" onClick={togglePwdReset} color="inherit" variant="outlined" >Cancelar</Button>
        <Button size="medium" onClick={handleResetPassword} color="secondary" variant="contained">Restablecer</Button>
      </CardActions>
    </Card>
  );

  
  const handleLogin = async () => {
    if (email === "" || password === "") {
      setSeverity("warning");
      setSnackbarOpen(true);
      setSnackbarMessage("Porfavor llene todos los campos.");
      return;
    }
    try {
      setShowBackdrop(true);
      const res = await axios.post(`${apiUrl}/login`, { "username": email,"password": password });
      AppConfig.parseResponse(res);
      if (res.status === 200) {
        eventReportService("login", { email: email });
        setRedirect({ trigger: true, route: '/' });
      }
    } catch (err) {
      if(err.response && err.response.status === 401) {
        setSeverity("error");
        setSnackbarOpen(true);
        setSnackbarMessage("Correo o Contraseña Incorrecta");
      } else {
        console.log(err.message);
      }
    } finally {
      setShowBackdrop(false);
    }
  }

  const handleKeyPress = (event) => {
    if(event.key === 'Enter'){
      handleLogin();
    }
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  const container = window !== undefined ? () => window().document.body : undefined;

  React.useEffect(() => {
    setNavbarState({ ...navbarState, showBack: false, showOptions: false, showSearch: false})
  }, [])

  return (
    <Container maxWidth="sm">
      {redirect.trigger && (
        <Navigate to={redirect.route} replace={true} />
      )}
      <Snackbar open={snackbarOpen} autoHideDuration={3000} onClose={handleClose} anchorOrigin={{ vertical: "top", horizontal: "center" }} sx={{mt: "3.2rem"}} >
        <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
      <Backdrop open={showBackdrop} sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, color: '#fff' }}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box sx={{ mt: 4 }}>

        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 0 }}>
          <img src="/logo.svg" alt="Logo" style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto', width: '20%', marginBottom: "24px" }} />
          <Typography variant="h4" component="h1" align='center' gutterBottom
            sx={{ fontFamily: 'Roboto', color: 'primary.main' }} >
            <b>MiGarita.Online</b>
          </Typography>
        </Box>
        {/* <Divider variant="middle" /> */}
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 0, mt: 4 }}>
          <TextField fullWidth size="medium" type='email' id='email' sx={{ my: 2 }} label="Correo Electronico" variant="outlined" value={email} onChange={(e) => setEmail(e.target.value)} />
          <TextField fullWidth size="medium" type='password' id='password' sx={{ mb: 2.5 }} label="Contraseña" variant="outlined" value={password} onChange={(e) => setPassword(e.target.value)} onKeyPress={handleKeyPress}/>
          <Button fullWidth variant="contained" color="secondary" sx={{mt: 5, py: 1.4}} onClick={handleLogin}>
            Iniciar Sesion
          </Button>
          <Button fullWidth variant="outlined" color="secondary" sx={{mt: 2, py: 1.4}} onClick={togglePwdReset}>
            Olvide mi Contraseña
          </Button>
          
        </Box>
      </Box>
      
      <Box sx={{ position: 'fixed', bottom: 0, width: '100%' }}>
        <Copyright />
      </Box>

      <Drawer
          container={container}
          anchor="bottom"
          variant="temporary"
          open={showPwdReset}
          onClose={togglePwdReset}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          PaperProps={{ elevation: 0, style: { backgroundColor: "transparent" } }}
          sx={{
            display: { xs: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: '440' }
          }}
        >
          {pwdResetDrawer}
        </Drawer>
      <BackgroundImage />
    </Container>
  );
}